<div class="page-header full bordered">
    <div class="container justify-content-between">
        <h2 class="page-header__title single">{{'politic' | translate}}</h2>
    </div>
</div>

<div class="page-content full">
    <div class="container">
        <div class="page-content__list mt-4 mb-3">

            <div class="table-responsive">
                <table class="table" cellpadding="0" cellspacing="0" border="0">
                    <thead>
                    <tr>
                        <th scope="col">Solicitação</th>
                        <th scope="col">{{'criado' | translate}}</th>
                        <th scope="col">{{'atualizado' | translate}}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="false">
                        <td colspan="7">
                            <p class="text-center py-5">Nenhum resultado encontrado.</p>
                        </td>
                    </tr>
                    <tr *ngFor="let x of listPolitics">
                        <td>{{x.typeSolicitation | translate}}</td>
                        <td>{{x.createdAt | date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</td>
                        <td>{{x.updatedAt | date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</td>
                        <td class="text-center">
                            <button (click)="onDetail(x)">
                                <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalPolitic" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">Política</h3>
                <a href="javascript:void('');"
                   class="btn" type="button" (click)="closeModal('modalPolitic')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="row">
                                <dl class="col">
                                    <dt>Tipo de Solicitação</dt>
                                    <dd>{{politic.typeSolicitation | translate}}</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{'criado' | translate}}</dt>
                                    <dd>{{politic.createdAt| date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{'atualizado' | translate}}</dt>
                                    <dd>{{politic.updatedAt| date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="input-material">
                                <input id="linkPolicyDocument" class="form-control" type="text"
                                       (change)="handlerFile('policy','doc-1')" required
                                       [(ngModel)]="politic.linkPolicyDocument"/>
                                <label for="linkPolicyDocument">Link {{'politic' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <label for="doc-1" class="btn-attachment"
                                   [ngClass]="{'btn-success': !this.isNullOrUndefined(this.pathImageObj.policy.path)}">
                                <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                <input type="file" id="doc-1" name="doc-1" required
                                       accept=".pdf"
                                       (change)="handlerUploadSingle($event, 'policy')"/>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="input-material">
                                <input id="linkTermDocument" class="form-control" type="text"
                                       (change)="handlerFile('term','doc-2')" required
                                       [(ngModel)]="politic.linkTermDocument"/>
                                <label for="linkTermDocument">Link Termos*</label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <label for="doc-2" class="btn-attachment"
                                   [ngClass]="{'btn-success': !this.isNullOrUndefined(this.pathImageObj.term.path)}">
                                <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                <input type="file" id="doc-2" name="doc-2" required
                                       accept=".pdf"
                                       (change)="handlerUploadSingle($event, 'term')"/>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 my-3">
                            <button (click)="onSave()" class="btn btn-block btn-warning mt-3">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

