import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    CreateSolicitationInput,
    FindAllPoliticsGQL,
    FindAllPoliticsQuery, FindAllPoliticsQueryVariables,
    Politic,
    UpdatePoliticGQL,
    UpdatePoliticInput
} from '../../../generated/graphql';
import {QueryRef} from "apollo-angular";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-config-politic',
    templateUrl: './config-politic.component.html',
    styleUrls: ['./config-politic.component.scss']
})
export class ConfigPoliticComponent extends BaseComponent implements OnInit {
    listPolitics: Politic[] = [];
    politic: Politic = new Politic();
    findAllPoliticsQuery?: QueryRef<FindAllPoliticsQuery, FindAllPoliticsQueryVariables>;

    pathImageObj = {
        policy: {name: '', path: ''},
        term: {name: '', path: ''}
    };
    // pathImage: string | ArrayBuffer;
    file: File = null;
    listFiles = [];

    constructor(public router: Router,
                public userService: UserService,
                public findAllPoliticsGQL: FindAllPoliticsGQL,
                public updatePoliticGQL: UpdatePoliticGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.findAllPoliticsQuery = this.findAllPoliticsGQL.watch();
        this.findAllPoliticsQuery.valueChanges.subscribe(({data}) => {
            this.listPolitics = data.findAllPolitics as Politic[];
        }, error => super.onError(error));
    }

    onDetail(p: Politic) {
        Object.assign(this.politic, p);
        this.listFiles = [];
        this.pathImageObj = {
            policy: {name: '', path: ''},
            term: {name: '', path: ''}
        };
        $('#doc-1').val('');
        $('#doc-2').val('');
        this.showModal('modalPolitic');
    }

    onSave() {
        const arrValidateFields = [
            {value: this.politic.linkPolicyDocument, text: 'Link ' + this.translate.instant('politic') + '* <br />'},
            {value: this.politic.linkTermDocument, text: 'Link Termos* <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
        }

        const input: UpdatePoliticInput = {
            id: this.politic.id,
            linkPolicyDocument: this.politic.linkPolicyDocument,
            linkTermDocument: this.politic.linkTermDocument,
        };
        this.updatePoliticGQL.mutate({input}).subscribe(({data}) => {
            if (!this.isNullOrUndefined(this.listFiles)) {
                this.actionUploadImage(this.politic.id);
            } else {
                this.finishUpdate();
            }
        }, error => super.onError(error));
    }

    handlerUploadSingle(event, type) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 50000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningMsgUploadFileMaxSize'), 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.pathImageObj[type].path = file.target.result;
            this.pathImageObj[type].name = files[0].name;
            if (type === 'policy') {
                this.politic.linkPolicyDocument = files[0].name;
            } else {
                this.politic.linkTermDocument = files[0].name;
            }
            this.listFiles = this.listFiles.filter((item) => item.type !== type);
            this.listFiles.push({file: files[0], path: file.target.result, type});
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    actionUploadImage(id: string) {
        this.listFiles.forEach((x, index) => {
            this.userService.upload(id, x.type, x.file, 'politic').subscribe({
                next: data => {
                    this.listFiles.splice(1, index);
                    if (this.listFiles.length === 1) {
                        this.finishUpdate();
                    }
                }, error: err => super.onError(err)

            });
        });
    }

    finishUpdate() {
        this.closeModal('modalPolitic');
        this.findAllPoliticsQuery.refetch().then();
        this.showMessage('Sucesso', 'Políticas e Termos atualizado com sucesso!', 'success');
        $('#doc-1').val('');
        $('#doc-2').val('');
    }

    handlerFile(type: string, element: string) {
        this.pathImageObj[type].path = '';
        this.pathImageObj[type].name = '';
        this.listFiles = this.listFiles.filter((item) => item.type !== type);
        $('#' + element).val('');
    }
}
