import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {
    ChangeSolicitationStatusGQL,
    GetSolicitationGQL,
    GetSolicitationQuery, LanguageSchool, ReturnActionStepGQL,
    Solicitation
} from '../../../generated/graphql';
import {ChangeSolicitation} from '../../model/model';
import {UserService} from '../../service/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {isNullOrUndefined} from 'util';

declare var $: any;


@Component({
    selector: 'app-detalhe-bolsa-de-idioma',
    templateUrl: './detalhe-bolsa-de-idioma.component.html',
    styleUrls: ['./detalhe-bolsa-de-idioma.component.scss']
})

export class DetalheBolsaDeIdiomaComponent extends BaseComponent implements OnInit {

    solicitation: Solicitation = new Solicitation();
    yearNow: any;
    solicitationQuery: QueryRef<GetSolicitationQuery>;
    receiptsUrl: any;
    dataChangeSolicitation: ChangeSolicitation = new ChangeSolicitation();
    response: any;
    user: any;

    flagLanguageCouses = false;

    constructor(public router: Router,
                public router2: ActivatedRoute,
                private getSolicitationGQL: GetSolicitationGQL,
                public loading: NgxSpinnerService,
                private changeSolicitationStatusGQL: ChangeSolicitationStatusGQL,
                public returnActionStepGQL: ReturnActionStepGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit() {

        this.router2.params.subscribe(url => {
                if (!this.isNullOrUndefined(url)) {
                    this.solicitationQuery = this.getSolicitationGQL.watch({id: url.id});
                    this.solicitationQuery.valueChanges.subscribe(({data}) => {
                        this.solicitation = data.getSolicitation as Solicitation;
                        this.handlerFlagLanguageCoures();
                    });

                    this.userService.user.subscribe({
                        next: data => {
                            const a: any = data;
                            this.user = a.getCurrentUser;
                        }, error: err => super.onError(err)
                    });

                    this.dataChangeSolicitation.id = url.id;

                    this.yearNow = (new Date()).getFullYear();

                    this.handlerTitlesBoxVertical();
                    setTimeout(() => {
                        this.handlerTitlesBoxVertical();
                    }, 1000);
                }
            }
        );
    }

    handlerFlagLanguageCoures() {
        let countFill = 0;
        this.solicitation.languageSchool.map((x) => {
            if (!this.isNullOrUndefined(x.name)) {
                countFill++;
            }
        });
        this.flagLanguageCouses = countFill > 0;
    }

    actionModal(isModal) {
        this.response = '';
        $('#' + isModal).modal('show');
    }

    validateResponse(status: string) {
        this.dataChangeSolicitation.status = status;
        if (status === 'approved') {
            return this.onChangeSolicitation();
        }

        const arrValidateFields = [
            {value: this.response, text: 'Justificativa* <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            return;
        }

        super.closeModal(status);
        this.dataChangeSolicitation.response = this.response;
        this.onChangeSolicitation();
    }

    onChangeSolicitation(): void {
        this.changeSolicitationStatusGQL.mutate({data: this.dataChangeSolicitation}).subscribe(
            (data) => {
                // this.solicitation = data;
                this.solicitationQuery.refetch();
                this.router.navigate(['/lista-de-solicitacoes']);
                if (this.dataChangeSolicitation.status === 'approved') {
                    $('#approved').modal('show');
                }
                super.closeModal('rejected');
            }, (err) => {
            }
        );
    }

    svgType(type) {
        switch (type) {
            case 'xlsx':
            case 'xls':
                return 'assets/svg/icon-metro-file-excel.svg';
            case 'docx':
            case 'doc':
            case 'txt':
                return 'assets/svg/icon-metro-file-word.svg';
            case 'png':
            case 'jpg':
                return 'assets/svg/icon-metro-file-image.svg';
            case 'ppt':
            case 'pptx':
                return 'assets/svg/icon-metro-file-powerpoint.svg';
            default:
                return 'assets/svg/icon-metro-file-pdf.svg';
        }
    }

    getNameRejected() {
        let lastRejectedUser = null;
        for (let i = this.solicitation?.solicitationStep.length - 1; i >= 0; i--) {
            const step = this.solicitation?.solicitationStep[i];
            if (step.status === 'rejected') {
                lastRejectedUser = step.user?.name;
                break;
            }
        }
        return lastRejectedUser;
    }

    returnAction(id: any) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('returnQuestion'), () => {
            this.returnActionStepGQL.mutate({id}).subscribe(({data}) => {
                this.solicitationQuery?.refetch();
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('returnConclued'), 'success');
            });
        });
    }

}

