import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';

declare var $: any;

export class BaseComponent {
    urlRoute: string;
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    getEnv() {
        return environment;
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
        } else if (language === 'en') {
            language = 'en';
        } else if (language === 'es') {
            language = 'es';
        }
        this.translate.use(language);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    destroyModal(idModal) {
        const elModalUser = $('#' + idModal);
        if (elModalUser[0]) {
            elModalUser.remove();
        }
        const backdrop = $('.modal-backdrop.show');
        if (backdrop[0]) {
            backdrop.remove();
        }
        $('body').removeClass('modal-open');
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    getRoute() {
        const t = this.router.url.split('/');
        return this.urlRoute = '/' + t[1];
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error) {
        console.log('aquiiiii');
        // this.loading.hide();
        this.toggleLoader(false);
        this.toggleLoaderCustom(false);
        if (error.status === 401) {
            this.router.navigate(['/login']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    onErrorRest(error) {
        const e = JSON.parse(error);
        if (e.statusCode === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        if (e.statusCode === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), e.message, 'warning');
            return;
        }

        if (e.statusCode === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value, object = false) {
        if (object) {
            return typeof value === 'undefined' || value == null;
        }
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    isCNPJValid(cnpj) {
        let i;
        const strErrorTitle = this.translate.instant('baseComponent.warningAttention');
        const strErrorDescription = this.translate.instant('baseComponent.warningMsgCNPJ');
        cnpj = cnpj.replace(/[^\d]+/g, '');

        /*if (cnpj === '') {
          this.showMessage(strErrorTitle, strErrorDescription, 'warning');
          return false;
        }*/

        if (cnpj.length !== 14) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        // Valida DVs
        const tamanho = cnpj.length - 2;
        const numeros = cnpj.substring(0, tamanho);
        const digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        const resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== Number(digitos.charAt(0))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        return true;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    handlerTitlesBoxVertical(): void {
        const el = $('.page-content__details__tab');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            $(x).css({width: $(x).parent()[0].getBoundingClientRect().height});
        });
    }

    dateValidate(p: any) {
        // p = (p instanceof Date) ? p : moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
        if (p instanceof Date) {
            p = moment(p).format('DD/MM/yyyy');
        } else if (p.indexOf('T') >= 0) {
            p = moment(p.split('T')[0], 'yyyy-MM-DD').format('DD/MM/yyyy');
        } else if (p.indexOf('/') >= 0 || p.indexOf('-') >= 0) {
            p = moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
        } else if (!this.isNullOrUndefined(p) && p.indexOf('/') < 0) {
            p = moment(p).format('DD/MM/yyyy');
        }
        let valReturn = true;
        if (this.isNullOrUndefined(p) || p === 'Invalid date') {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgData'), 'warning');
            valReturn = false;
        } else if (!moment(p, 'DD/MM/YYYY', true).isValid()) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgData'), 'warning');
            valReturn = false;
        }
        return valReturn;
    }

    getStatusName(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    getProfileNameByRole(s: string) {
        if (!this.isNullOrUndefined(s)) {
            if (s === 'user') {
                s = s + '1';
            }
            return this.translate.instant(s);
        }
    }

    getReceiptName(r: string) {
        if (!this.isNullOrUndefined(r)) {
            return this.translate.instant(r);
        }
    }

    getTranslate(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    toggleLoaderCustom(b: boolean) {
        if (!b) {
            $('#loaderBox2').fadeOut();
        } else {
            $('#loaderBox2').fadeIn();
        }
    }

    getSymbolCurrency(typeOfCurrency: string) {
        switch (typeOfCurrency) {
            case 'dolar':
                return '$';
            case 'euro':
                return '€';
            case 'real':
                return 'R$';
            default :
                return 'R$';
        }
    }

    onLogout() {
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningExit'), () => {
            localStorage.clear();
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;
        });
    }

    getNameFile(name: string) {
        return name + '-' + moment().utc(true).format() + '.xlsx';
    }
}
