import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PaintScreen} from "./model/pain-screen";

declare var $: any;
declare var document;
declare var Element;
declare var window;
declare var requestAnimFrame;

@Component({
    selector: 'app-paint-screen',
    templateUrl: './paint-screen.component.html',
    styleUrls: ['./paint-screen.component.scss']
})
export class PaintScreenComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() flagSignature = false;
    @Output() flagSignatureChange = new EventEmitter<boolean>();

    @Output() paintScreen = new EventEmitter<PaintScreen>();

    showPanel = false;
    imageSignatureFile: File;
    imageSignature = undefined;
    canvas: any;
    ctx: any;

    constructor(public router: Router,
                public translate: TranslateService
    ) {
        super(router, translate);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.isNullOrUndefined(changes.flagSignature)) {
            if (changes.flagSignature.currentValue) {
                this.actionSignature();
            }
        }
    }

    ngAfterViewInit() {
        $(() => {
            this.canvas = document.getElementById('sig-canvas');
            this.ctx = this.canvas.getContext('2d');
            this.handlerPaint();
            $(window).resize(() => {
                const W = this.canvas.offsetWidth, H = this.canvas.offsetHeight;
                const temp = this.ctx.getImageData(0, 0, W, H);
                this.ctx.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
                this.ctx.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
                this.ctx.putImageData(temp, 0, 0);
            });
        });
    }


    actionSignature() {
        let a = 0;
        const b = $('html, body');
        b.toggleClass('no-scroll');
        // this.flagSignature = !this.flagSignature;
        this.showPanel = !this.showPanel;
        if (!this.showPanel) {
            a = $('.signature').offset().top;
            this.flagSignatureChange.emit(false);
            this.paintScreen.emit({preview: this.imageSignature, file: this.imageSignatureFile});
        }
        b.stop().animate(10, {scrollTop: a});
        this.toggleFullScreen();
    }

    toggleFullScreen() {
        if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    handlerPaint() {
        $(() => {
            const st = this;
            this.canvas.width = parseInt(document.getElementById('sketch').offsetWidth.toString(), 0);
            this.canvas.height = parseInt(document.getElementById('sketch').offsetHeight.toString(), 0);
            window.requestAnimFrame = (function (callback) {
                return window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.oRequestAnimationFrame ||
                    window.msRequestAnimaitonFrame ||
                    function (callback) {
                        window.setTimeout(callback, st.canvas.width / st.canvas.height);
                    };
            })();

            this.ctx.strokeStyle = '#222222';
            this.ctx.lineWith = 2;

            // Set up the UI
            const sigText: any = document.getElementById('sig-dataUrl');
            const sigImage: any = document.getElementById('sig-image');
            const clearBtn: any = document.getElementById('sig-clearBtn');
            const submitBtn: any = document.getElementById('sig-submitBtn');
            clearBtn.addEventListener('click', function (e) {
                clearCanvas();
                // sigText.innerHTML = 'Data URL for your signature will go here!';
                // sigImage.setAttribute('src', '');
            }, false);
            submitBtn.addEventListener('click', function (e) {
                const dataUrl = st.canvas.toDataURL('image/png');
                const blobBin = atob(dataUrl.split(',')[1]);
                const array = [];
                for (let i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i));
                }
                st.imageSignatureFile = new File([new Uint8Array(array)], 'signature.png', {
                    type: 'image/png',
                    lastModified: new Date().getTime()
                });
                st.imageSignature = dataUrl;

                console.log('imagem aqui');
                console.log(st.imageSignature);
                console.log(st.imageSignatureFile);
                st.actionSignature();
            }, false);
            // Set up mouse events for drawing
            let drawing = false;
            let mousePos = {x: 0, y: 0};
            let lastPos = mousePos;
            this.canvas.addEventListener('mousedown', function (e) {
                // st.flagSignature = true; // FLAG ASSINATURA PREENCHIDA
                drawing = true;
                lastPos = getMousePos(st.canvas, e);
            }, false);
            this.canvas.addEventListener('mouseup', function (e) {
                drawing = false;
            }, false);
            this.canvas.addEventListener('mousemove', function (e) {
                mousePos = getMousePos(st.canvas, e);
            }, false);
            // Set up touch events for mobile, etc
            this.canvas.addEventListener('touchstart', function (e) {
                mousePos = getTouchPos(st.canvas, e);
                const touch: any = e.touches[0];
                const mouseEvent: any = new MouseEvent('mousedown', {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                st.canvas.dispatchEvent(mouseEvent);
            }, false);
            this.canvas.addEventListener('touchend', function (e) {
                const mouseEvent: any = new MouseEvent('mouseup', {});
                st.canvas.dispatchEvent(mouseEvent);
            }, false);
            this.canvas.addEventListener('touchmove', function (e) {
                const touch: any = e.touches[0];
                const mouseEvent: any = new MouseEvent('mousemove', {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                st.canvas.dispatchEvent(mouseEvent);
            }, false);
            // Prevent scrolling when touching the canvas
            document.body.addEventListener('touchstart', function (e) {
                if (e.target === st.canvas) {
                    e.preventDefault();
                }
            }, false);
            document.body.addEventListener('touchend', function (e) {
                if (e.target === st.canvas) {
                    e.preventDefault();
                }
            }, false);
            document.body.addEventListener('touchmove', function (e) {
                if (e.target === st.canvas) {
                    e.preventDefault();
                }
            }, false);

            // Get the position of the mouse relative to the canvas
            function getMousePos(canvasDom, mouseEvent) {
                const rect = canvasDom.getBoundingClientRect();
                return {
                    x: mouseEvent.clientX - rect.left,
                    y: mouseEvent.clientY - rect.top
                };
            }

            // Get the position of a touch relative to the canvas
            function getTouchPos(canvasDom, touchEvent) {
                const rect = canvasDom.getBoundingClientRect();
                return {
                    x: touchEvent.touches[0].clientX - rect.left,
                    y: touchEvent.touches[0].clientY - rect.top
                };
            }

            // Draw to the canvas
            function renderCanvas() {
                if (drawing) {
                    st.ctx.moveTo(lastPos.x, lastPos.y);
                    st.ctx.lineTo(mousePos.x, mousePos.y);
                    st.ctx.stroke();
                    lastPos = mousePos;
                }
            }

            function clearCanvas() {
                st.canvas.width = st.canvas.width;
            }

            // Allow for animation
            (function drawLoop() {
                requestAnimFrame(drawLoop);
                renderCanvas();
            })();
        });
    }
}
