import {
    CreateColaboratorInput,
    CreateCourseInput,
    CreateInstitutionInput,
    CreateLanguageSchoolInput
} from "../../generated/graphql";

export class CreateSolicitation {
    type: string;
    receiptJustification: string;
    colaborators: CreateColaboratorInput[];
    course: CreateCourseInput;
    institution: CreateInstitutionInput;
    courseName: string;
    courseStatus: string;
    startDate: Date;
    languageSchool: CreateLanguageSchoolInput[];

    constructor() {
        this.colaborators = [];
        this.course = new CreateCourseInput();
        this.institution = new CreateInstitutionInput();
    }
}

export class languageSchool {
    name: string;
    time: string;

    constructor() {
        this.name = '';
        this.time = '';
    }

}

export class User {
    name: string;

    constructor() {
        this.name = '';
    }
}

export class ChangeSolicitation {
    id: string;
    status: string;
    response: string;

    constructor() {
        this.id = '';
        this.status = '';
        this.response = '';
    }
}

export class ChangeColaborator {
    id: string;
    status: string;

    constructor() {
        this.id = '';
        this.status = '';
    }
}

export class Colaborator {
    name: string;
    re?: string;
    directorArea: string;
    ramal: string;
    currentJob: string;
    salaryRange?: string;
    admissionDate?: Date;
    costCenter: string;
    areaManager: string;
    activities: string;
    isRequester: boolean;
    status: string;
    unity?: Unity;
    // isDeleted: boolean;

    constructor() {
        this.name = '';
        this.re = '';
        this.directorArea = '';
        this.ramal = '';
        this.currentJob = '';
        this.salaryRange = '';
        this.admissionDate = null;
        this.costCenter = '';
        this.areaManager = '';
        this.activities = '';
        this.isRequester = false;
        // this.status = '';
        // this.unity.id = '';
    }
}

export class Course {
    name?: string;
    type: string;
    attendingYear?: number;
    durationYear?: number;
    durationMonths?: number;
    registrationDate?: Date;
    start?: Date;
    end?: Date;
    value?: number;
    workload?: string;
    language?: string;
    level?: string;

    constructor() {
    }
}

export class Institution {
    name: string;
    email: string;
    phone: string;
    phone2: string;
    responsible: string;
    cnpj_cpf: string;
    unity: string;
    address: string;

    constructor() {
    }
}


export class Unity {
    id: string;
    name: string;

    constructor() {
        this.id = '';
    }
}
