export class Opened {

    step: string;
    open: number;
    finish: number;

    constructor() {
    }
}

