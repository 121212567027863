import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {GetCurrentUserGQL, User, ValidatedFirstAccessGQL} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User = new User();
    newPassword = '';
    confirmPassword = '';

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                public validatedFirstAccessGQL: ValidatedFirstAccessGQL,
                private getCurrentUserGQL: GetCurrentUserGQL) {
        super(router, translate);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        if (this.userService.getStatusAuthenticated()) {
            this.getCurrentUser();
        } else {
            localStorage.clear();
            this.userService.isAuthenticated = false;
            this.router.navigate(['/logout']);
        }
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function () {
            $(this).appendTo('body');
        });
    }

    getCurrentUser() {
        this.getCurrentUserGQL.watch().valueChanges.subscribe(({data}) => {
            this.user = data.getCurrentUser as User;
            if (!this.user.firstAccess) {
                this.showModal('fistAccess');
            }
            this.userService.updateUser(data);
        });
    }

    fistAccess() {
        const arrValidateFields = [
            {
                value: this.newPassword,
                text: 'Nova Senha <br/>'
            },
            {
                value: this.confirmPassword,
                text: 'Confirmar Senha <br/>'
            },
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.newPassword !== this.confirmPassword) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Senhas diferentes!`, 'warning');
            return;
        }
        this.validatedFirstAccessGQL.mutate({newPassword: this.newPassword}).subscribe(({data}) => {
            this.closeModal('fistAccess');
            this.showMessage('Sucesso', 'Senha alterada com sucesso!', 'success');
        }, error => super.onError(error));
    }

}
