<div class="paint-container" [ngClass]="{'is-open' : showPanel}">
    <div id="sketch" class="paint-box">
        <canvas id="sig-canvas" style="object-fit: cover;"></canvas>
        <div class="signature" style="background: #0D2E5D;color: white">
            <div class="clear">&nbsp;</div>
            <h4 class="text-center flex-grow-1 mb-0">Desenhe abaixo</h4>
            <button class="clear" id="sig-clearBtn">
                <svg-icon src="assets/svg/cross.svg"></svg-icon>
                <span>Limpar</span>
            </button>
        </div>
        <div class="page-content__buttons button_back">
            <button (click)="actionSignature()">
                <svg-icon src="assets/svg/arrow.svg"></svg-icon>
            </button>
        </div>
        <div class="page-content__buttons button_save">
            <button class="btn btn-warning" id="sig-submitBtn">{{'salvar'|translate}}</button>
        </div>
    </div>
</div>
