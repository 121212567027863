import {RequestGroup} from './request-group';
import {RequestHystory} from './request-hystory';
import {RequestUnifications} from './request-unifications';
import {User} from "../../../generated/graphql";

export class Request {

    id: string;
    name: string;
    code: string;
    motive: string;
    channelDistribution: string;
    unitMeasure: string;
    description: string;
    recordHolder: string;
    ministryRecord: string;
    dispatchedBy: string;
    recordValidity: Date;
    manufacturer: string;
    dueDate: number;
    codeSap: string;
    descriptionFinish: string;
    country: string;
    status: string;
    step: number;
    type: string;
    ean: string;
    dum: string;
    unifications: RequestUnifications[];
    createdAt: Date;
    updatedAt: Date;
    group: RequestGroup[];
    history: RequestHystory[];
    createdBy: User;

    constructor() {
        this.group = [];
        this.history = [];
        this.unifications = [];
    }
}
