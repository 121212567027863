// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://euro-estudo-service.duka.digital/v1',
  // base_url: 'http://localhost:3000/v1',
  // base_url: 'https://localhost:44394/api/v1',
  client_id: 'adcd88a9-8ed3-4440-ba05-cf0fa86bfffd',
  tenant_id: '65077b68-6db1-4a06-b930-a013aecff707',
  isLocal: false,
  // base_graphql: 'http://localhost:3000/graphql',
  base_graphql: 'https://euro-estudo-service.duka.digital/graphql',
  isLoginMock: true,
  handlerRequest: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
