import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {Observable} from 'rxjs';
import {QueryRef} from 'apollo-angular';
import {
    Country,
    CreateUserCountryInput,
    CreateUserDirectorInput,
    CreateUserGQL,
    CreateUserInput,
    CreateUserManagerInput,
    CreateUserUnityInput,
    DirectorsGQL,
    GetCountriesGQL,
    GetCountriesQuery,
    GetUnitiesGQL,
    GetUnitiesQuery,
    RemoveUserGQL,
    SearchManagersGQL,
    SearchUserAll,
    SearchUsersGQL,
    SearchUsersQuery,
    Unity,
    UpdateUserGQL,
    UpdateUserInput,
    User,
    UserPage
} from '../../../generated/graphql';
import {map} from 'rxjs/operators';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {

    currentUser: User = new User();
    userPage: UserPage = new UserPage();
    usersQuery: QueryRef<SearchUsersQuery>;

    countries: Observable<Array<Country>>;
    countryQuery: QueryRef<GetCountriesQuery>;
    unities: Observable<Array<Unity>>;
    unityQuery: QueryRef<GetUnitiesQuery>;
    managers: User[] = [];
    directors: User[] = [];

    model: User = new User();
    userCountry: string;
    userUnity: string;

    manager = [];
    director = [];

    filter = new SearchUserAll();

    currentPage = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    dropdownSettingsUnities = {};

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                private searchUsersGQL: SearchUsersGQL,
                private getCountriesGQL: GetCountriesGQL,
                private getUnitiesGQL: GetUnitiesGQL,
                private createUserGQL: CreateUserGQL,
                private updateUserGQL: UpdateUserGQL,
                private searchManagersGQL: SearchManagersGQL,
                private directorsGQL: DirectorsGQL,
                private removeUserGQL: RemoveUserGQL
    ) {
        super(router, translate);
    }

    ngOnInit() {
        this.dropdownSettingsUnities = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
        };

        this.userService.user.subscribe({
            next: data => {
                const a: any = data;
                this.currentUser = a.getCurrentUser;
                if (this.currentUser?.profile === 'user') {
                    this.router.navigate(['/']);
                }
            }, error: err => super.onError(err)
        });

        this.filter.country = null;
        this.filter.profile = null;
        this.filter.unity = null;
        this.getManagers();
        this.getCountries();
        this.getUnities();
        this.getUsers();
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    getUsers() {
        this.usersQuery = this.searchUsersGQL.watch({input: this.filter});
        this.usersQuery.valueChanges.subscribe(({data}) => {
            this.userPage = data.searchUsers as UserPage;
        });
    }

    getManagers() {
        this.searchManagersGQL.watch().valueChanges.subscribe(({data}) => {
            this.managers = data.managers as User[];
        });

        this.directorsGQL.watch().valueChanges.subscribe(({data}) => {
            this.directors = data.directors as User[];
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countries = this.countryQuery.valueChanges.pipe(map(result => result.data.countries as Array<Country>));
    }

    getUnities() {
        this.unityQuery = this.getUnitiesGQL.watch();
        this.unities = this.unityQuery.valueChanges.pipe(map(result => result.data.getUnities as Array<Unity>));
    }

    onFilter() {
        this.usersQuery.refetch({input: this.filter});
    }

    actionNew() {
        this.userCountry = null;
        this.userUnity = null;
        this.manager = [];
        this.director = [];
        this.model = new User();
        this.getManagers();
        this.showModal('modalUser');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome<br>'},
            {value: this.model.re, text: 'RE<br>'},
            {value: this.model.email, text: 'Email<br>'},
            {value: this.model.profile, text: 'Perfil<br>'},
            {value: this.userCountry, text: 'País<br>'},
            {value: this.userUnity, text: 'Unidade<br>'},
            {value: this.model.costCenter, text: 'Centro de custo<br>'},

        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br> ${stringError}`);
            return;
        }

        if (!this.isEMailValid(this.model.email)) {
            return;
        }

        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    actionEdit(user: User) {
        Object.assign(this.model, user);
        this.userCountry = this.model.country.id;
        this.userUnity = this.model.unity.id;
        this.manager = !this.isNullOrUndefined(this.model.managerBy) ? [{
            id: this.model.managerBy?.id,
            name: this.model.managerBy?.name
        }] : [];
        this.director = !this.isNullOrUndefined(this.model.directorBy) ? [{
            id: this.model.directorBy?.id,
            name: this.model.directorBy?.name
        }] : [];
        this.showModal('modalUser');
    }

    actionCreatedUser() {
        const managerBy: CreateUserManagerInput = !this.isNullOrUndefined(this.manager[0]?.id) ? {
            id: this.manager[0].id,
        } : undefined;
        const directorBy: CreateUserDirectorInput = !this.isNullOrUndefined(this.director[0]?.id) ? {
            id: this.director[0].id,
        } : undefined;
        const countryInput: CreateUserCountryInput = {
            id: this.userCountry,
        };
        const unityInput: CreateUserUnityInput = {
            id: this.userUnity,
        };

        const data = new CreateUserInput();
        data.profile = this.model.profile;
        data.name = this.model.name;
        data.email = this.model.email;
        data.country = countryInput;
        data.re = this.model.re;
        data.unity = unityInput;
        data.costCenter = this.model.costCenter;
        data.directorBy = directorBy;

        if (this.model.profile !== 'manager') {
            data.managerBy = managerBy;
        }

        this.createUserGQL.mutate({input: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch({input: this.filter});
            this.showMessage('Sucesso', 'Usuário criado com  sucesso', 'success');
            this.closeModal('modalUser');
        });
    }

    actionUpdateUser() {
        const managerBy: CreateUserManagerInput = !this.isNullOrUndefined(this.manager[0]?.id) ? {
            id: this.manager[0].id,
        } : undefined;
        const directorBy: CreateUserDirectorInput = !this.isNullOrUndefined(this.director[0]?.id) ? {
            id: this.director[0].id,
        } : undefined;

        const data = new UpdateUserInput();
        data.id = this.model.id;
        data.profile = this.model.profile;
        data.name = this.model.name;
        data.email = this.model.email;
        data.country = {
            id: this.userCountry
        };

        data.unity = {
            id: this.userUnity,
        };

        data.re = this.model.re;
        data.costCenter = this.model.costCenter;
        data.directorBy = directorBy;

        if (this.model.profile !== 'manager') {
            data.managerBy = managerBy;
        }

        this.updateUserGQL.mutate({input: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch({input: this.filter});
            this.showMessage('Sucesso', 'Usuário editado com  sucesso', 'success');
            this.closeModal('modalUser');
        });
    }

    actionDelete(userId) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse usuário?', () => {
            this.removeUserGQL.mutate({id: userId}).subscribe((result) => {
                super.showMessage('Sucesso', 'Usuário deletado com sucesso.', 'success');
                this.usersQuery.refetch({input: this.filter});
            });
        });
    }

    pageChanged(event: number) {
        this.filter.page = event;
        this.usersQuery.refetch({input: this.filter}).then();
        this.currentPage = event;
    }

    // getExcel() {
    //     const token = localStorage.getItem('token') as string;
    //     let url = `${environment.base_url}/users/search-user/excel?token=${token}`;
    //
    //     if (!super.isNullOrUndefined(this.filter.keyword)) {
    //         url += ('&keyword=' + this.filter.keyword);
    //         console.log('url keyword');
    //         console.log(url);
    //     }
    //     if (!super.isNullOrUndefined(this.filter.country)) {
    //         url += ('&country=' + this.filter.country);
    //         console.log('url country');
    //         console.log(url);
    //     }
    //     if (!super.isNullOrUndefined(this.filter.profile)) {
    //         url += ('&profile=' + this.filter.profile);
    //         console.log('url profile');
    //         console.log(url);
    //     }
    //     if (!super.isNullOrUndefined(this.filter.unity)) {
    //         url += ('&unity=' + this.filter.unity);
    //         console.log('url unity');
    //         console.log(url);
    //     }
    //     window.open(encodeURI(url));
    //     console.log('encodeURI(url)');
    //     console.log(encodeURI(url));
    // }

    getExcel() {
        this.userService.getExcel(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    downloadHandlerFile(e: any) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    imporExcel(event: any) {
        const files = event.target.files;
        this.userService.uploadImport('users', files[0]).subscribe((data) => {
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('importSuccess'), 'success');
        }, error => super.onErrorRest(error));
    }
}
