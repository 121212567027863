import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-register-detail',
    templateUrl: './register-detail.component.html',
    styleUrls: ['./register-detail.component.scss']
})
export class RegisterDetailComponent implements OnInit {

    constructor(private location: Location) {
    }

    ngOnInit(): void {
    }

    handlerNext(number: number) {

    }

    handlerConfirm() {

    }

}
