import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import * as moment from 'moment';
import {
    CreateSolicitationInput,
    FindAllPoliticsGQL, GetUserSolicitationByReGQL,
    Politic, Solicitation, SolicitationsForRefundLanguageGQL, SolicitationsForRefundScholarshipGQL, User
} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-requests',
    templateUrl: './requests.component.html',
    styleUrls: ['./requests.component.scss']
})
export class RequestsComponent extends BaseComponent implements OnInit, OnDestroy {

    dataSolicitation: CreateSolicitationInput = new CreateSolicitationInput();

    preScholarship = {courseName: '', startDate: null, courseStatus: null};
    preLanguage = [{name: '', time: ''}, {name: '', time: ''}, {name: '', time: ''}];

    loading = false;
    bolsa = 1;
    idioma = 1;
    treinamento = 1;
    findAllPolitics: Politic[] = [];
    refundLanguage: Solicitation[] = [];
    refundScholarship: Solicitation[] = [];
    listSolicitationByRe: Solicitation[] = [];
    solicitationType: string;
    userRe = '';
    user: User = new User();
    limitDay: number;

    constructor(public router: Router,
                public translate: TranslateService,
                public findAllPoliticsGQL: FindAllPoliticsGQL,
                public solicitationsForRefundLanguageGQL: SolicitationsForRefundLanguageGQL,
                public solicitationsForRefundScholarshipGQL: SolicitationsForRefundScholarshipGQL,
                public getUserSolicitationByReGQL: GetUserSolicitationByReGQL,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('estudo');
        this.destroyModal('treinamento');
        this.destroyModal('idioma');
        this.destroyModal('questScholarship');
        this.destroyModal('collaboratorScholarship');
        this.destroyModal('questLanguage');
        this.destroyModal('collaboratorLanguage');
        this.destroyModal('solicitationBy');
    }

    ngOnInit(): void {
        this.limitDay = Number(moment().startOf('day').utcOffset(0, true).format('DD'));

        this.userService.solicitation.subscribe({
            next: data => {
                const a: any = data;
                this.dataSolicitation = a;
            }, error: err => super.onError(err)
        });

        this.userService.user.subscribe({
            next: data => {
                const a: any = data;
                this.user = a.getCurrentUser;
            }, error: err => super.onError(err)
        });

        this.solicitationsForRefundLanguageGQL.watch().valueChanges.subscribe(({data}) => {
            this.refundLanguage = data.solicitationsForRefundLanguage as Solicitation[];
        });

        this.solicitationsForRefundScholarshipGQL.watch().valueChanges.subscribe(({data}) => {
            this.refundScholarship = data.solicitationsForRefundScholarship as Solicitation[];
        });

        this.findAllPoliticsGQL.watch().valueChanges.subscribe(({data}) => {
            const value = data.findAllPolitics as Politic[];
            this.findAllPolitics.push(value.find((e) => e.typeSolicitation === 'scholarship'));
            this.findAllPolitics.push(value.find((e) => e.typeSolicitation === 'training'));
            this.findAllPolitics.push(value.find((e) => e.typeSolicitation === 'language'));
        });
    }

    fakeLoading(): void {
        setTimeout(() => {
            this.loading = false;
        }, 500);
    }

    scholarshipStep(n): void {
        this.fakeLoading();
        this.bolsa = n;
    }

    idiomaStep(n): void {
        this.fakeLoading();
        this.idioma = n;
    }

    treinamentoStep(n): void {
        this.fakeLoading();
        this.treinamento = n;
    }

    onInitSolicitation(s: string): void {
        this.dataSolicitation = new CreateSolicitationInput();
        this.dataSolicitation.type = s;
        if (s === 'scholarship') {
            this.dataSolicitation.courseName = this.preScholarship.courseName;
            this.dataSolicitation.startDate = this.preScholarship.startDate;
            this.dataSolicitation.courseStatus = this.preScholarship.courseStatus;
            $('#estudo').modal('hide');
        } else if (s === 'language') {
            this.dataSolicitation.languageSchool = this.preLanguage;
            $('#idioma').modal('hide');
        }
        this.userService.updateDataSolicitation(this.dataSolicitation);
        const func = {
            'scholarship': () => {
                return 'bolsa-de-estudos';
            },
            'training': () => {
                return 'treinamentos';
            },
            'language': () => {
                return 'bolsa-de-idiomas';
            }
        };
        this.router.navigate([func[s]()]);
    }

    onClearCourseDate(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.preScholarship[str] = '';
            } else {
                this.preScholarship[str] = moment(a, 'DD/MM/yyyy').format();
            }
        });
    }

    onValidatePreRequest(s: string): void {
        if (s === 'scholarship') {
            const arrValidateFields = [
                {
                    value: this.preScholarship.courseName,
                    text: this.translate.instant('solicitacao.coursename') + ' <br />'
                },
                {
                    value: this.preScholarship.startDate,
                    text: this.translate.instant('solicitacao.initialdate') + ' <br />'
                },
                {value: this.preScholarship.courseStatus, text: this.translate.instant('statusOfCourse')}
            ];
            console.log('arrValidateFields');
            console.log(arrValidateFields);
            const stringError = this.validateField(arrValidateFields);
            if (!super.isNullOrUndefined(stringError)) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
                return;
            }
        } else if (s === 'language') {
            let numFill = 0;
            this.preLanguage.forEach((x) => {
                if (!this.isNullOrUndefined(x.name) && !this.isNullOrUndefined(x.time)) {
                    numFill++;
                }
            });
            if (numFill === 0) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningField'), 'warning');
                return;
            }
        }
        this.onInitSolicitation(s);
    }

    refundDetail(route, modal: any) {
        this.closeModal(modal);
        this.router.navigate([route]);
    }

    handlerHidden(router) {
        if (this.limitDay > 15 && this.getEnv().handlerRequest) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Seu reembolso não foi aceito! O prazo de reembolso é até dia 15 de cada mês, podendo sofrer alteração em casos de feriados ou finais de semana. Caso de dúvidas consulte a política vigente.', 'warning');
            return;
        }
        this.showModal(router);
    }

    solicitationModal(type: string) {
        this.solicitationType = type;
        this.userRe = '';
        this.listSolicitationByRe = [];
        this.showModal('solicitationBy');
    }

    searchByRe() {
        this.getUserSolicitationByReGQL.watch({
            solicitationType: this.solicitationType,
            userRE: this.userRe
        }).valueChanges.subscribe(({data}) => {
            this.listSolicitationByRe = data.getUserSolicitationByRE as Solicitation[];
        }, error => super.onError(error));
    }
}
