import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {UserService} from '../service/user.service';
import {Observable, throwError, BehaviorSubject, timer} from "rxjs";
import {catchError, debounce, finalize, tap} from 'rxjs/operators';

declare var $: any;

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {

    requests = 0;
    arrNoLoader = [
        '/assets',
        '/svg',
        'GetNotification',
        'GetCountries',
        'currentUserNotification'
    ];
    private requestSubject = new BehaviorSubject<boolean>(false);
    private debounceLoader$ = this.requestSubject.pipe(
        debounce(show => show ? timer(0) : timer(1000))
    );

    constructor(private userService: UserService) {
        // Subscribe to the debounced loader observable
        this.debounceLoader$.subscribe(show => {
            this.toggleLoader(show);
        });
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let countLoaderBlock = 0;
        let newHeaders = req.headers;
        const culture = localStorage.getItem('culture') as string;

        if (this.userService.getToken() != null && typeof this.userService.getToken() !== 'undefined') {
            newHeaders = newHeaders.append('Authorization', 'Bearer ' + this.userService.getToken());
        }
        if (culture != null) {
            newHeaders = newHeaders.append('Culture', culture);
        }

        const authReq = req.clone({headers: newHeaders});

        if (req.body != null && req.body.operationName != null) {
            this.arrNoLoader.map((e, i) => {
                if (req.body.operationName.indexOf(e) >= 0) {
                    countLoaderBlock++;
                }
            });
        }

        if (countLoaderBlock === 0) {
            this.requests++;
            this.requestSubject.next(true);  // Trigger loader to show
        }

        return next.handle(authReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.userService.signOut();
                    location.reload();
                }
                return throwError(error);
            }),
            finalize(() => {
                if (countLoaderBlock === 0) {
                    this.requests--;
                    if (this.requests <= 0) {
                        this.requests = 0;
                        this.requestSubject.next(false);  // Trigger loader to hide after debounce time
                    }
                }
            })
        );
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

}
