<div class="page-header">
    <div class="container justify-content-center">
        <a [routerLink]="['/lista-de-solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title"><strong>{{'solicitacao.card1title' | translate}}</strong></h2>
    </div>
</div>

<div class="reason-rejected" *ngIf="solicitation?.status === 'rejected'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #D81212;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder">
                        <strong>{{'reprovadopor'|translate}} {{getNameRejected()}}</strong>
                    </h4>
                    <h4 class="text-primary font-weight-bolder"><strong>{{'motivoreprova'|translate}}</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                 data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #D81212;">
                    <span class="text-primary" style="font-size: 18px"> {{solicitation.response}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="page-content">
    <div class="container">
        <div class="page-content__status">
            <!-- VARIAÇÃO DE STATUS: approving, rejected e approved -->
            <div class="infos no-border" [ngClass]="solicitation?.status">
                <span class="number">
                    <span class="page-header__number mt-1">Nº <strong>{{solicitation?.code}}</strong></span>
                </span>
                <span class="ml-3 text flex-column align-items-start tag tag-secondary tag-left color">
                    <span class="head small">Status</span>
                    <strong>{{getStatusName(solicitation?.status)}}</strong>
                </span>
                <span class="text flex-column align-items-start mr-lg-auto">
                    <span class="head small">{{'atualizado' | translate}}</span>
                    <span class="color">{{solicitation?.updatedAt | date:'dd/MM/yyyy':'UTC+0'}}</span>
                </span>
                <button class="btn btn-primary mr-4"
                        (click)="saveConfirm()">{{'visualizarsolicitacoes'|translate}}</button>
                <span class="text flex-column align-items-start mr-0">
                    <span class="head small">{{'novocadastro.criado' | translate}}</span>
                    <span class="text-primary">{{solicitation?.createdAt | date:'dd/MM/yyyy':'UTC+0'}}</span>
                </span>
            </div>
            <div class="custom-nav full mt-3">
                <!-- VARIAÇÃO DE STATUS: active, error e done -->
                <ul class="nav nav-tabs custom-nav__menu">
                    <li class="nav-item" *ngFor="let s of solicitation?.solicitationStep">
                        <span class="nav-link "
                              [ngClass]="{'active':s.step === solicitation?.step,
                              'done':s.status === 'approved',
                              'error':s.status === 'rejected'}">
<!--                            <span>2</span>-->
                            <div class="bubble"></div>
                            <span class="label">{{getProfileNameByRole(s.role)}}</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <ng-container *ngFor="let c of solicitation?.colaborators">
            <article class="page-content__details box mb-md-4"
                     *ngIf="solicitation?.colaborators.length === 1 && c.isRequester">
                <div class="page-content__details__tab">{{'treinamento.requester'| translate}}</div>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{'nome' | translate}}</h4>
                        <p class="text">{{c.name}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3" *ngIf="c.dateOfBirth">
                        <h4 class="title">{{'bolsa.nascimento' | translate}}</h4>
                        <p class="text">{{c.dateOfBirth | date:'dd/MM/yyyy':'UTC+0'}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2">
                        <h4 class="title">RE</h4>
                        <p class="text">{{c.re}}</p>
                    </div>
                </div>
                <ng-container *ngIf="false">
                    <hr>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{'corpele'|translate}}</h4>
                            <p class="text">{{c.skinColor?.name}}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{'generosex'|translate}}</h4>
                            <p class="text">{{c.genderIdentity?.name}}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{'orientsex'|translate}}</h4>
                            <p class="text">{{c.sexualOrientation?.name}}</p>
                        </div>
                    </div>
                </ng-container>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-2">
                        <h4 class="title">{{'unidade' | translate}}</h4>
                        <p class="text">{{c.unity.name}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3 mt-sm-3 mt-lg-0">
                        <h4 class="title">{{'departamento/setor' | translate}}</h4>
                        <p class="text">{{c.areaManager}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2 mt-sm-3 mt-lg-0">
                        <h4 class="title">{{'ramal' | translate}}</h4>
                        <p class="text">{{c.ramal}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{'cargo' | translate}}</h4>
                        <p class="text">{{c.currentJob}}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{'bolsa.faixa' | translate}}</h4>
                        <p class="text">{{c.salaryRange}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{'bolsa.admissao' | translate}}</h4>
                        <p class="text">{{c.admissionDate | date:'dd/MM/yyyy':'UTC+0'}}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{'bolsa.centro' | translate}}</h4>
                        <p class="text">{{c.costCenter}}</p>
                    </div>
                    <div class="col-6 col-sm-8 col-lg-3">
                        <h4 class="title">{{'bolsa.gerente' | translate}}</h4>
                        <p class="text">{{c.directorArea}}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12">
                        <h4 class="title">{{'bolsa.resumo' | translate}}</h4>
                        <p class="text text-break-word">{{c.activities}}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <article class="page-content__details box mb-md-4" *ngIf="solicitation?.colaborators?.length > 1">
            <div class="page-content__details__tab">{{'treinamento.collaborator'| translate}}</div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{'treinamento.name' | translate}}</th>
                            <th scope="col">{{'treinamento.re' | translate}}</th>
                            <th scope="col">{{'bolsa.nascimento' | translate}}</th>
                            <th scope="col">{{'treinamento.unity' | translate}}</th>
                            <th scope="col">{{'treinamento.departmentSector' | translate}}</th>

                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let c of solicitation?.colaborators">
                            <ng-container *ngIf="!c.isRequester">
                                <tr *ngIf="solicitation?.step === 0 || (solicitation?.step > 0 && c.status !== 'rejected')">
                                    <th scope="row">{{c.name}}</th>
                                    <td>{{c.re}}</td>
                                    <td>{{c.dateOfBirth | date: 'dd/MM/yyyy':'UTC+0'}}</td>
                                    <td>{{c.unity.name}}</td>
                                    <td>{{c.directorArea}}</td>

                                </tr>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </article>


        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{'bolsa.curso' | translate}}</div>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{'typeOfCourse' | translate}}</h4>
                    <p class="text">{{getTranslate(solicitation?.course?.type)}}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{'solicitacao.coursename' | translate}}</h4>
                    <p class="text">{{solicitation?.course?.name}}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{'bolsa.anocurso' | translate}}</h4>
                    <p class="text">{{yearNow - solicitation?.course?.attendingYear}}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{'bolsa.duracurso' | translate}}</h4>
                    <p class="text">
                        <span class="mr-1"
                              *ngIf="solicitation?.course?.durationYear > 0">{{solicitation?.course?.durationYear}} {{'bolsa.anos' | translate}}</span>
                        <span *ngIf="solicitation?.course?.durationMonths > 0">{{solicitation?.course?.durationMonths}} {{'bolsa.meses' | translate}}</span>
                    </p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{'bolsa.datamatricula' | translate}}</h4>
                    <p class="text">{{solicitation?.course?.registrationDate | date:'dd/MM/yyyy':'UTC+0'}}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{'bolsa.datainicio' | translate}}</h4>
                    <p class="text">{{solicitation?.course?.start | date:'dd/MM/yyyy':'UTC+0'}}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{'bolsa.datatermino' | translate}}</h4>
                    <p class="text">{{solicitation?.course?.end | date:'dd/MM/yyyy':'UTC+0'}}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{'bolsa.numberOfInstallments' | translate}}</h4>
                    <p class="text">{{solicitation?.course?.numberOfInstallments}}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{'bolsa.valor' | translate}}</h4>
                    <strong class="text">{{solicitation?.course?.value | currency:getSymbolCurrency(solicitation?.course?.typeOfCurrency)}}</strong>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{'bolsa.instituicao' | translate}}</div>
            <div class="row pt-md-4">
                <div class="col-6 col-sm-6">
                    <h4 class="title">{{'bolsa.instituicaonome' | translate}}</h4>
                    <p class="text">{{solicitation?.institution?.name}}</p>
                </div>
                <div class="col-6 col-sm-6">
                    <ng-container *ngIf="this.documentType === 'CNPJ'">
                        <h4 class="title">CNPJ</h4>
                    </ng-container>
                    <ng-container *ngIf="this.documentType === 'CPF'">
                        <h4 class="title">CPF</h4>
                    </ng-container>
                    <p class="text">{{solicitation?.institution?.cnpj_cpf}}</p>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitation?.receipts)">
            <div class="page-content__details__tab">{{'bolsa.comprovantes' | translate}}</div>
            <div class="row justify-content-center pt-3">
                <div class="col-12 col-md-4 text-center mr-3" *ngFor="let r of solicitation?.receipts">
                    <h4 class="title large">{{getReceiptName(r.type)}}</h4>
                    <a [href]="r.url" target="_blank" class="attachment d-flex flex-column align-items-center">
                        <img class="attach" *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>
                        <!--                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" src="assets/svg/icon-metro-file-pdf.svg"></svg-icon>-->
                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" [src]="svgType(r.extension)"></svg-icon>
                        <p>{{r.name}}.{{r.extension}}</p>
                    </a>
                </div>
                <div class="flex-column d-flex justify-content-center gap-20">
                    <ng-container *ngIf="solicitation.isAcceptPolicy">
                        <p class="text">Declaro estar ciente com a Política Bolsa de Estudos</p>
                    </ng-container>
                    <ng-container *ngIf="solicitation.isAcceptTerms">
                        <p class="text">Declaro estar ciente com o Termo de Ciência</p>
                    </ng-container>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4 d-flex"
                 *ngIf="!this.isNullOrUndefined(solicitation?.signature)">
            <div class="page-content__details__tab">{{'signature'|translate}}</div>
            <img class="signature" src="{{solicitation?.signature}}" alt="signature"/>
        </article>

        <article class="page-content__details box mb-md-4"
                 *ngIf="!isNullOrUndefined(solicitation?.receiptJustification)">
            <div class="page-content__details__tab">{{'justificativa' | translate}}</div>
            <div class="row pt-md-4">
                <div class="col-12 mb-4">
                    <p class="text">{{solicitation?.receiptJustification}}</p>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4"
                 *ngIf="!isNullOrUndefined(solicitation?.courseName || solicitation?.courseStatus || solicitation?.startDate)">
            <div class="page-content__details__tab">{{'bolsaestudos'|translate}}</div>
            <div class="row pt-md-4">
                <div class="col-6 col-sm-4 col-lg-3">
                    <h4 class="title">{{'nomecurso'|translate}}</h4>
                    <p class="text">{{solicitation.courseName}}</p>
                </div>

                <div class="col-6 col-sm-4 col-lg-3" *ngIf="!isNullOrUndefined(solicitation?.startDate)">
                    <h4 class="title">{{'datainicio'|translate}}</h4>
                    <p class="text">{{solicitation.startDate | date : 'dd/MM/yyyy'}}</p>
                </div>

                <div class="col-6 col-sm-4 col-lg-3" *ngIf="!isNullOrUndefined(solicitation?.courseStatus)">
                    <h4 class="title">Status</h4>
                    <p class="text">{{solicitation.courseStatus === 'finished' ? 'Curso Completo' : 'Curso Incompleto'}}</p>
                </div>
            </div>
        </article>

        <div class="d-flex align-items-center mb-4 w-100" [ngClass]="{'justify-content-end' : user?.profile !== 'admin', 'justify-content-between' : user?.profile === 'admin'}">
            <ng-container *ngIf="user?.profile === 'admin'">
                <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0" (click)="returnAction(solicitation.id)">{{'novocadastro2.retornar' | translate}}</button>
            </ng-container>

            <ng-container *ngIf="solicitation?.status === 'pending'">
                <div *ngIf="(user?.profile !== 'user'
            || user?.profile ==='director')
            && (solicitation?.solicitationStep[solicitation.step].role === user.profile || user?.profile === 'admin')"
                     class="page-content__buttons mb-0 justify-content-center justify-content-md-end">
                    <button (click)="actionModal('rejected')"
                            class="btn btn-light btn-fixed btn-lg decline mb-3 mr-4 "
                            *ngIf="user?.profile !='consultant'">{{'novocadastro2.reprovar' | translate}}
                    </button>
                    <button (click)="actionModal('review')"
                            class="btn btn-warning btn-fixed btn-lg mb-3 mr-4"
                            *ngIf="user?.profile !== 'consultant'">{{'solicitarrevisao'|translate}}
                    </button>
                    <button (click)="validateResponse('approved')"
                            class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0"
                            *ngIf="user?.profile !== 'consultant'">{{'novocadastro2.aprovar' | translate}}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<div id="rejected" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card1title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{'reprovaestudos'|translate}}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{'escreva' | translate}}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('rejected')" class="btn btn-block btn-warning mt-4">
                    {{'Salvar' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="review" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card1title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{'escreva' | translate}}</textarea>
                </div>
                <hr>
                <!--                <div class="row">-->
                <!--                    <div class="col-7">-->
                <!--                        <p class="small">Aceitar revisão do processo iniciado.</p>-->
                <!--                    </div>-->
                <!--                    <div class="col-5 text-right">-->
                <!--                        <div class="custom-control custom-switch">-->
                <!--                            <input type="checkbox" class="custom-control-input" id="accept-terms2">-->
                <!--                            <label class="custom-control-label" for="accept-terms"></label>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <button (click)="validateResponse('review')" class="btn btn-block btn-warning mt-4"
                        data-dismiss="modal">
                    {{'Salvar' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="resume" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="page-content__form full mt-0 ml-0 mr-0 col-12">
                    <table class="table table-custom table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{'solicitacao.code' | translate}}</th>
                            <th scope="col">{{'solicitacao.type' | translate}}</th>
                            <th scope="col">{{'solicitacao.status' | translate}}</th>
                            <th scope="col">{{'solicitacao.createdAt' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="isNullOrUndefined(colaboratorSolicitations.length < 1)">
                            <p class="mt-3">Nenhuma solicitação encontrada!</p>
                        </ng-container>
                        <ng-container *ngFor="let x of colaboratorSolicitations">
                            <tr>
                                <td scope="row">{{x.code}}</td>
                                <td>{{x.type === 'scholarship' ? 'Bolsa de estudos' : (x.type === 'language' ? 'Linguagem' : 'Treinamento')}}</td>
                                <td>{{x.status === 'pending' ? this.translate.instant('solicitacao.pendente') : (x.status === 'review' ? this.translate.instant('solicitacao.analise') : this.translate.instant('solicitacao.aprovado'))}}</td>
                                <td>{{x.createdAt | date: 'dd/MM/yyyy'}}</td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
