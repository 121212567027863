import {Values} from './values';

export class Month {

    month: [];
    values: Values[];

    constructor() {
        this.month = [];
        this.values = [];
    }
}

