import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    search(filter, currentPage) {
        const url = `${this.BASE_URL}/request/search/${currentPage}`;
        return this.http.post(url, filter);
    }

    step(step, model) {
        const url = `${this.BASE_URL}/request/step-${step}`;
        return this.http.post(url, model);
    }

    getId(id) {
        const url = `${this.BASE_URL}/request/${id}`;
        return this.http.get(url);
    }

    like(id) {
        const url = `${this.BASE_URL}/request/like/${id}`;
        return this.http.get(url);
    }

}
