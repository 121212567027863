<div class="page-header">
    <div class="container">
        <h2 class="page-header__title">{{'solicitacao.title' | translate}}
            <strong>{{'solicitacao.titlebold' | translate}}</strong></h2>
        <a href="#" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
    </div>
</div>

<div class="page-content">
    <div class="container">
        <p class="m-0">
            {{'pararealizarasuasolicitacao'|translate}}
        </p>
        <ul class="page-content__blocks">
            <li>
                <div class="request_div is-cursor" (click)="scholarshipStep(1)" data-toggle="modal"
                     data-target="#estudo">
                    <div class="icon">
                        <svg-icon src="assets/svg/icon-1.svg"></svg-icon>
                    </div>
                    <div class="content">
                        <h2>{{'solicitacao.card1title' | translate}}</h2>
                        <p>{{'studyGrant.description' | translate}}<br>{{'studyGrant.applicationLink' | translate}}</p>
                    </div>
                    <div class="col_link">
                        <a *ngIf="!this.isNullOrUndefined(this.findAllPolitics[0]?.linkPolicyDocument)"
                           href="{{this.findAllPolitics[0].linkPolicyDocument!}}"
                           target="_blank" class="message">{{'solicitacao.politicabolsa' | translate}}</a>
                        <br>
                        <a *ngIf="!this.isNullOrUndefined(this.findAllPolitics[0]?.linkTermDocument)"
                           href="{{this.findAllPolitics[0].linkTermDocument}}"
                           target="_blank" class="message">{{'solicitacao.termociencia' | translate}}</a>
                    </div>
                </div>
            </li>
            <li>
                <div class="request_div is-cursor">
                    <div class="d-flex flex-column flex-sm-row flex-grow-1" (click)="onInitSolicitation('training')">
                        <div class="icon">
                            <svg-icon src="assets/svg/yellowbook.svg"></svg-icon>
                        </div>
                        <div class="content">
                            <h2>{{'solicitacao.card2title' | translate}}</h2>
                            <p>{{'trainingGrant.description' | translate}}
                                <br>{{'trainingGrant.applicationLink' | translate}}</p>
                        </div>
                    </div>
                    <a *ngIf="!this.isNullOrUndefined(this.findAllPolitics[1]?.linkPolicyDocument)"
                       href="{{this.findAllPolitics[1].linkPolicyDocument}}"
                       target="_blank" class="message">{{'solicitacao.politicatreinamentos' | translate}}</a>
                </div>
            </li>
            <li>
                <div class="request_div is-cursor" (click)="idiomaStep(1)" data-toggle="modal" data-target="#idioma">
                    <div class="icon">
                        <svg-icon src="assets/svg/yellowglob.svg"></svg-icon>
                    </div>
                    <div class="content">
                        <h2>{{'solicitacao.card3title' | translate}}</h2>
                        <p>{{'languageGrant.description' | translate}}
                            <br>{{'languageGrant.applicationLink' | translate}}</p>
                    </div>
                    <div class="col_link">
                        <a *ngIf="!this.isNullOrUndefined(this.findAllPolitics[2]?.linkPolicyDocument)"
                           href="{{this.findAllPolitics[2].linkPolicyDocument}}"
                           target="_blank" class="message">{{'solicitacao.politicaidiomas' | translate}}</a>
                        <br>
                        <a *ngIf="!this.isNullOrUndefined(this.findAllPolitics[2]?.linkTermDocument)"
                           href="{{this.findAllPolitics[2].linkTermDocument}}"
                           target="_blank" class="message">{{'solicitacao.termociencia' | translate}}</a>
                    </div>

                </div>
            </li>
            <li>
                <div class="request_div is-cursor" (click)="handlerHidden('questScholarship')">
                    <div class="icon">
                        <svg-icon src="assets/svg/yellowmoney.svg"></svg-icon>
                    </div>
                    <div class="content">
                        <h2>{{'solicitacao.card4title' | translate}}</h2>
                        <p>{{'monthlyApplication' | translate}}</p>
                    </div>

                </div>
            </li>
            <li>
                <div class="request_div is-cursor" (click)="handlerHidden('questLanguage')">
                    <div class="icon">
                        <svg-icon src="assets/svg/yellowmoney.svg"></svg-icon>
                    </div>
                    <div class="content">
                        <h2>{{'solicitacao.card5title' | translate}}</h2>
                        <p>{{'monthlyApplication' | translate}}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div id="estudo" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card1title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <span class="modal-loading" *ngIf="loading"></span>
                <div *ngIf="bolsa == 1" style="width: 100%;">
                    <h2 class="modal-body-title">{{'solicitacao.innercardtitle' | translate}}</h2>
                    <ul class="modal-body-list">
                        <li>
                            <a (click)="scholarshipStep(2)"><strong>{{'solicitacao.innercardquestionbold1' | translate}}</strong> {{'solicitacao.innercardquestion1' | translate}}
                            </a>
                        </li>
                        <li>
                            <a (click)="scholarshipStep(3)"><strong>{{'solicitacao.innercardquestionbold2' | translate}}</strong>  {{'solicitacao.innercardquestion1' | translate}}
                            </a>
                        </li>
                        <li>
                            <a (click)="onInitSolicitation('scholarship')"
                               data-dismiss="modal"><strong>{{'solicitacao.innercardquestionbold3' | translate}}</strong> {{'solicitacao.innercardquestion3' | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="bolsa == 2" class="modal-body-error">
                    <p>{{'solicitacao.modalNotConclusion' | translate}}</p>
                    <button class="btn btn-block btn-light mt-4" (click)="scholarshipStep(1)">Voltar</button>
                </div>
                <div *ngIf="bolsa == 3" style="width: 100%;">

                    <h2 class="modal-body-title">{{'dadossubsidiados'|translate}}</h2>
                    <fieldset class="modal-body-form">
                        <div class="input-material w-100">
                            <input class="form-control" type="text" required maxlength="250"
                                   name="courseNamePre"
                                   [(ngModel)]="preScholarship.courseName"/>
                            <label>{{'solicitacao.coursename' | translate}}</label>
                        </div>
                        <div class="input-material w-half w-100">
                            <input class="form-control" type="text" required maxlength="250"
                                   name="courseNamePre"
                                   [clearIfNotMatch]="true"
                                   (blur)="onClearCourseDate($event, 'startDate')"
                                   [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                   [dropSpecialCharacters]="false"
                                   [ngModel]="preScholarship.startDate | date: 'dd/MM/yyyy'"/>
                            <label>{{'solicitacao.initialdate' | translate}}</label>
                        </div>
                        <div class="d-flex flex-wrap flex-md-nowrap gap-30 justify-content-between">
                            <label class="checkbox-styled">
                                <input type="radio" name="course" id="complete"
                                       [checked]="preScholarship.courseStatus === 'finished'"
                                       [(ngModel)]="preScholarship.courseStatus"
                                       [value]="'finished'"/>
                                <span>{{'solicitacao.coursecomplete' | translate}}</span>
                            </label>
                            <label class="checkbox-styled">
                                <input type="radio" name="course" id="incomplete"
                                       [checked]="preScholarship.courseStatus === 'unfinished'"
                                       [(ngModel)]="preScholarship.courseStatus"
                                       [value]="'unfinished'"/>
                                <span>{{'solicitacao.courseincomplete' | translate}}</span>
                            </label>
                        </div>
                        <button (click)="onValidatePreRequest('scholarship')"
                                class="btn btn-block btn-warning mt-4">{{'enviar' | translate}}</button>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="treinamento" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card2title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <span class="modal-loading" *ngIf="loading"></span>
                <div *ngIf="treinamento == 1">
                    <h2 class="modal-body-title">{{'solicitacao.innercardtitle' | translate}}</h2>
                    <ul class="modal-body-list">
                        <li>
                            <a (click)="treinamentoStep(2)"><strong>{{'solicitacao.innercardquestionbold1' | translate}} </strong>{{'solicitacao.innercardquestion1' | translate}}
                            </a>
                        </li>
                        <li>
                            <a (click)="treinamentoStep(3)"><strong>{{'solicitacao.innercardquestionbold2' | translate}}</strong>{{'solicitacao.innercardquestion1' | translate}}
                            </a>
                        </li>
                        <li>
                            <a (click)="onInitSolicitation('training')"
                               data-dismiss="modal"><strong>{{'solicitacao.innercardquestionbold3' | translate}}</strong> {{'solicitacao.innercardquestion3' | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="treinamento == 2" class="modal-body-error">
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
                    <button class="btn btn-block btn-light mt-4"
                            (click)="treinamentoStep(1)">{{'voltar' | translate}}</button>
                </div>
                <span *ngIf="treinamento == 3">
                    <h2 class="modal-body-title">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</h2>
                    <form action="#" class="modal-body-form">
                        <div class="input-material w-100">
                            <input class="form-control" type="text" required name="text"/>
                            <label>{{'solicitacao.coursename' | translate}}</label>
                        </div>
                        <div class="input-material w-half w-50">
                            <input class="form-control" type="text" required name="text"/>
                            <label>{{'solicitacao.initialdate' | translate}}</label>
                        </div>
                        <div class="d-flex gap-30 justify-content-between">
                            <label class="checkbox-styled">
                                <input type="radio" name="course" id="completeB"/>
                                <span>{{'solicitacao.coursecomplete' | translate}}</span>
                            </label>
                            <label class="checkbox-styled">
                                <input type="radio" name="course" id="incompleteB">
                                <span>{{'solicitacao.courseincomplete' | translate}}</span>
                            </label>
                        </div>
                        <button (click)="onInitSolicitation('training')" class="btn btn-block btn-warning mt-4"
                                data-dismiss="modal">{{'enviar' | translate}}</button>
                    </form>
                </span>
            </div>
        </div>
    </div>
</div>

<div id="idioma" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card3title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <span class="modal-loading" *ngIf="loading"></span>
                <div *ngIf="idioma == 1">
                    <h2 class="modal-body-title">{{'solicitacao.innercardtitle' | translate}}</h2>
                    <ul class="modal-body-list">
                        <li>
                            <a (click)="idiomaStep(2)"><strong>{{'solicitacao.innercardquestionbold1' | translate}}</strong> {{'solicitacao.innercardquestion2' | translate}}
                            </a>
                        </li>
                        <li>
                            <a (click)="idiomaStep(4)"><strong>{{'solicitacao.innercardquestionbold2' | translate}}</strong> {{'solicitacao.innercardquestion2' | translate}}
                            </a>
                        </li>
                        <li>
                            <a (click)="onInitSolicitation('language')"
                               data-dismiss="modal"><strong>{{'solicitacao.innercardquestionbold3' | translate}}</strong> {{'solicitacao.bolsarequest' | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="idioma == 2" class="modal-body-error">
                    <p>{{'cantconclude' | translate}}</p>
                    <button class="btn btn-block btn-light mt-4" (click)="idiomaStep(1)">Voltar</button>
                </div>
                <div *ngIf="idioma == 3" class="modal-body-error">
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</p>
                    <button class="btn btn-block btn-light mt-4"
                            (click)="idiomaStep(1)">{{'voltar' | translate}}</button>
                </div>
                <div *ngIf="idioma == 4">
                    <h2 class="modal-body-title">{{'solicitacao.escola' | translate}}</h2>
                    <fieldset class="modal-body-form mt-4 ml-0 mr-0">
                        <div class="row mb-0 mb-md-2" *ngFor="let x of preLanguage; let i = index;">
                            <div class="col-12 col-md-8">
                                <div class="input-material w-100">
                                    <input class="form-control" type="text" required name="text" [(ngModel)]="x.name"/>
                                    <label>Curso</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="input-material">
                                    <input class="form-control" type="text" required name="text" maxlength="25"
                                           [(ngModel)]="x.time"/>
                                    <label>Tempo</label>
                                </div>
                            </div>
                        </div>
                        <button (click)="onValidatePreRequest('language')"
                                class="btn btn-block btn-warning mt-4">{{'enviar' | translate}}</button>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="questScholarship" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card4title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body" style="min-height: initial">
                <span class="modal-loading" *ngIf="false"></span>
                <div>
                    <h2 class="modal-body-title">{{'solicitacao.innercardtitle' | translate}}</h2>
                    <ul class="modal-body-list">
                        <li>
                            <a (click)="solicitationModal('scholarship')"
                               data-dismiss="modal">{{'oreembolsoeparaseus'|translate}}
                                <strong>{{'colaboradores?'|translate}}</strong>
                            </a>
                        </li>
                        <li>
                            <a (click)="showModal('collaboratorScholarship')"
                               data-dismiss="modal">{{'oreembolsoparavoce'|translate}}
                                <strong>{{'voce?'|translate}}</strong>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="collaboratorScholarship" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card4title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column" style="max-height: 60vh;">
                <div *ngIf="this.isNullOrUndefined(refundScholarship)" class="text-center my-auto py-4">
                    <span>{{'noRefundsfound'|translate}}</span>
                </div>
                <div style="overflow-y: auto">
                    <div class="card is-cursor mb-3"
                         (click)="refundDetail('/reembolsos/' + x.id + '/' + this.user.re,'collaboratorScholarship')"
                         *ngFor="let x of refundScholarship">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <dl class="d-flex">
                                        <dt>Nº</dt>
                                        <dd> {{x.code}}</dd>
                                    </dl>
                                </div>
                                <div class="col">
                                    <dl>
                                        <dt>Colaboradores:</dt>
                                        <dd><span *ngFor="let c of x.colaborators;let i = index"><span
                                                *ngIf="i > 0">,</span>{{c.name}}</span></dd>
                                    </dl>
                                </div>
                                <div class="col-12">
                                    <dl>
                                        <dd>{{x.createdAt | date:'dd/MM/yyyy HH:mm'}}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="questLanguage" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card5title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body" style="min-height: initial">
                <span class="modal-loading" *ngIf="false"></span>
                <div>
                    <h2 class="modal-body-title">{{'solicitacao.innercardtitle' | translate}}</h2>
                    <ul class="modal-body-list">
                        <li>
                            <a (click)="solicitationModal('language')"
                               data-dismiss="modal">{{'oreembolsoeparaseus'|translate}}
                                <strong>{{'colaboradores?'|translate}}</strong>
                            </a>
                        </li>
                        <li>
                            <a (click)="showModal('collaboratorLanguage')"
                               data-dismiss="modal">{{'oreembolsoparavoce'|translate}}
                                <strong>{{'voce?'|translate}}</strong>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="collaboratorLanguage" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card5title' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column" style="max-height: 60vh;">
                <div *ngIf="this.isNullOrUndefined(refundLanguage)" class="text-center my-auto py-4">
                    <span>{{'noRefundsfound'|translate}}</span>
                </div>
                <div style="overflow-y: auto">
                    <div class="card is-cursor mb-3"
                         (click)="refundDetail('/reembolsos-idiomas/' + x.id + '/' + this.user.re,'collaboratorLanguage')"
                         *ngFor="let x of refundLanguage">
                        <div class="card-body">
                            <div class="row ">
                                <div class="col">
                                    <dl class="d-flex">
                                        <dt>Nº</dt>
                                        <dd> {{x.code}}</dd>
                                    </dl>
                                </div>
                                <div class="col">
                                    <dl>
                                        <dt>Colaboradores:</dt>
                                        <dd><span *ngFor="let c of x.colaborators;let i = index"><span
                                                *ngIf="i > 0">,</span>{{c.name}}</span></dd>
                                    </dl>
                                </div>
                                <div class="col-12">
                                    <dl>
                                        <dd>{{x.createdAt | date:'dd/MM/yyyy HH:mm'}}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="solicitationBy" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{(solicitationType == 'scholarship' ? 'solicitacao.card4title' : 'solicitacao.card5title') | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column" style="max-height: 60vh;min-height:initial">
                <div class="row mb-3">
                    <div class="col">
                        <div class="input-material mb-0 w-100">
                            <input [(ngModel)]="this.userRe" id="keyword" class="form-control" type="text" required/>
                            <label for="keyword">{{'buscarRe' | translate}}</label>
                        </div>
                    </div>
                    <div class="col-auto">
                        <a (click)="searchByRe()" class="btn btn-lg btn-primary">
                            <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/search.svg"></svg-icon>
                        </a>
                    </div>
                </div>
                <div style="overflow-y: auto">
                    <div class="card is-cursor mb-3"
                         (click)="refundDetail((solicitationType == 'scholarship' ? '/reembolsos/':'/reembolsos-idiomas/') + x.id +'/'+this.userRe,'solicitationBy')"
                         *ngFor="let x of listSolicitationByRe">
                        <div class="card-body">
                            <div class="row ">
                                <div class="col">
                                    <dl class="d-flex">
                                        <dt>Nº</dt>
                                        <dd> {{x.code}}</dd>
                                    </dl>
                                </div>
                                <div class="col">
                                    <dl>
                                        <dt>Colaboradores:</dt>
                                        <dd><span *ngFor="let c of x.colaborators;let i = index"><span
                                                *ngIf="i > 0">,</span>{{c.name}}</span></dd>
                                    </dl>
                                </div>
                                <div class="col-12">
                                    <dl>
                                        <dd>{{x.createdAt | date:'dd/MM/yyyy HH:mm'}}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
