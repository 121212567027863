import {Component, OnInit} from '@angular/core';
import {
    FindAllSalaryRangeGQL, FindAllSalaryRangeQuery, FindAllSalaryRangeQueryVariables,
    Politic, SalaryRange, UpdatePoliticGQL, UpdatePoliticInput, UpdateSalaryRangeGQL, UpdateSalaryRangeInput
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../base/base.component";

@Component({
    selector: 'app-config-salary',
    templateUrl: './config-salary.component.html',
    styleUrls: ['./config-salary.component.scss']
})
export class ConfigSalaryComponent extends BaseComponent implements OnInit {
    listSalaryRange: SalaryRange[] = [];
    salaryRange: SalaryRange = new SalaryRange();
    findAllSalaryQuery: QueryRef<FindAllSalaryRangeQuery, FindAllSalaryRangeQueryVariables>;

    constructor(public router: Router,
                public findAllSalaryRangeGQL: FindAllSalaryRangeGQL,
                public updateSalaryRangeGQL: UpdateSalaryRangeGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.findAllSalaryQuery = this.findAllSalaryRangeGQL.watch();
        this.findAllSalaryQuery.valueChanges.subscribe(({data}) => {
            this.listSalaryRange = data.findAllSalaryRange as SalaryRange[];
        }, error => super.onError(error));
    }

    onDetail(s: SalaryRange) {
        this.salaryRange = s;
        this.showModal('modalSalary');
    }

    onSave() {
        const arrValidateFields = [
            {value: this.salaryRange.value, text: 'Valor* <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
        }

        const input: UpdateSalaryRangeInput = {
            id: this.salaryRange.id,
            value: this.salaryRange.value,
        };
        this.updateSalaryRangeGQL.mutate({input}).subscribe(({data}) => {
            this.closeModal('modalSalary');
            this.findAllSalaryQuery.refetch().then();
            this.showMessage('Sucesso', 'Valor atualizado com sucesso!', 'success');
        }, error => super.onError(error));
    }
}
