import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Request} from '../../model/request/request';
import {RequestService} from '../../service/request.service';
import {EnumService} from '../../service/enum.service';
import {Enum} from '../../model/enum';
import {RequestUnifications} from '../../model/request/request-unifications';
import {UserService} from '../../service/user.service';

declare var $;
declare var moment;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    step = 1;
    createaAt: string;

    modelRequest: Request = new Request();
    modelRequestResult: Request = new Request();
    listChannel: Enum[] = [];
    listMeasure: Enum[] = [];
    listDispatched: Enum[] = [];
    listDueDate = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
        11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25
    ];
    listLike: Request[] = [];

    isEdit = false;
    stepOneDisabled = false;
    stepTwoDisabled = false;
    stepThreeDisabled = false;
    stepFourDisabled = false;
    stepFiveDisabled = false;

    btnOneDisabled = false;
    btnTwoDisabled = false;
    btnThreeDisabled = false;
    btnFourDisabled = false;
    btnFiveDisabled = false;

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public translate: TranslateService,
                public requestService: RequestService,
                public notificationService: UserService,
                public enumService: EnumService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.route2.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id)) {
                // this.loading.show();
                this.isEdit = true;
                this.getid(data.id);
                setTimeout(() => {
                    this.step = this.modelRequest.step;
                    if (this.checkProfile()) {
                        this.handlerBack(this.modelRequest.step);
                        this.handlerCheckNext();
                    } else {
                        this.handlerBack(this.modelRequest.step + 1);
                    }

                    if (this.modelRequest.step === 3) {
                        this.actionLike();
                    }
                    this.setDatePicker();
                    // this.loading.hide();
                }, 1000);
            }
        });
        this.blockClickStep();
        this.createaAt = moment();
        this.actionChannel();
        this.actionMeasure();
        this.actionDispatched();
    }

    checkProfile() {
        const profile = this.notificationService.getUserLocal().profile;
        const stepActive = this.modelRequest.step;
        const profiles = ['local', 'ar_local', 'ar_inter', 'arts', 'register'];

        const index = profiles.indexOf(profile);

        if (stepActive >= (index + 1)) {
            return true;
        }

        return false;
        // switch (stepActive) {
        //     case 1:
        //         if (profile === 'local') {
        //             return true;
        //         }
        //         break;
        //     case 2:
        //         if (profile === 'ar_local') {
        //             return true;
        //         }
        //         break;
        //     case 3:
        //         if (profile === 'ar_inter') {
        //             return true;
        //         }
        //         break;
        //     case 4:
        //         if (profile === 'arts') {
        //             return true;
        //         }
        //         break;
        //     case 5:
        //         if (profile === 'register') {
        //             return true;
        //         }
        //         break;
        // }
        // return false;
    }

    ngAfterViewInit(): void {
        this.setDatePicker();
    }

    ngOnDestroy(): void {
        // $('#modalNoteResume').modal('hide');
        // const elModalRemove = $('#modalNoteResume');
        // if (elModalRemove[0]) {
        //     elModalRemove.remove();
        // }
    }

    blockClickStep() {
        $('.nav-link').on('click', (e) => {
            if (!$(e.currentTarget).hasClass('finish')) {
                e.preventDefault();
                e.stopImmediatePropagation();
            } else {
                const step = Number($(e.currentTarget)[0].id.replace(/([^\d])+/gim, ''));
                this.handlerBack(step);
            }
        });
    }

    handlerNext(e) {
        this.step = e;

        $('.nav a:eq(' + e + ')').tab('show');

        /*switch (e) {
          case 0:
            $('.nav_step').css({
              background: 'white'
            });
            $('.nav a:eq(0)').tab('show');
            $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
            break;
          case 1:
            $('.nav_step').css({
              background: 'linear-gradient(90deg, #FAE100  27%, #FFFFFF 27%)'
            });
            $('.nav a:eq(1)').tab('show');
            $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
            break;
          case 2:
            $('.nav_step').css({
              background: 'linear-gradient(90deg, #FAE100  50%, #FFFFFF 27%)'
            });
            $('.nav a:eq(2)').tab('show');
            $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
            break;
          case 3:
            $('.nav_step').css({
              background: 'linear-gradient(90deg, #FAE100 73%, #FFFFFF 27%)'
            });
            $('.nav a:eq(3)').tab('show');
            $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
            break;
          case 4:
            $('.nav_step').css({
              background: 'linear-gradient(90deg, #FAE100 100%, #FFFFFF 27%)'
            });
            $('.nav a:eq(4)').tab('show');
            $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
            break;
        }*/
        this.handlerCheckBar(e + 1);
        this.handlerCheckDisable(e + 1);
    }

    handlerBack(step) {
        this.step = step;
        this.getid(this.modelRequest.id);

        $('.nav a:eq(' + (step - 1) + ')').tab('show');

        this.handlerCheckBar(step);
        this.handlerCheckDisable(step - 1);

        // this.handlerNext(step - 1);
    }

    handlerCheckBar(i: number) {
        const arrFill = [
            'white',
            'linear-gradient(90deg, #FAE100 27%, #FFFFFF 27%)',
            'linear-gradient(90deg, #FAE100 50%, #FFFFFF 27%)',
            'linear-gradient(90deg, #FAE100 73%, #FFFFFF 27%)',
            'linear-gradient(90deg, #FAE100 100%, #FFFFFF 27%)'
        ];
        $('.nav_step').css({
            background: arrFill[i - 1]
        });
        $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
    }

    handlerCheckDisable(i: number) {
        const profile = this.notificationService.getUserLocal().profile;
        this.notificationService.getNotification();
        switch (this.modelRequest.step) {
            case 0:
                this.stepOneDisabled = false;
                this.stepTwoDisabled = true;
                this.stepThreeDisabled = true;
                this.stepFourDisabled = true;
                this.stepFiveDisabled = true;
                break;
            case 1:
                this.stepOneDisabled = true;
                this.stepTwoDisabled = false;
                this.stepThreeDisabled = true;
                this.stepFourDisabled = true;
                this.stepFiveDisabled = true;
                break;
            case 2:
                this.stepOneDisabled = true;
                this.stepTwoDisabled = true;
                this.stepThreeDisabled = false;
                this.stepFourDisabled = true;
                this.stepFiveDisabled = true;
                break;
            case 3:
                this.stepOneDisabled = true;
                this.stepTwoDisabled = true;
                this.stepThreeDisabled = profile !== 'arts';
                this.stepFourDisabled = false;
                this.stepFiveDisabled = true;
                break;
            case 4:
            case 5:
                this.stepOneDisabled = true;
                this.stepTwoDisabled = true;
                this.stepThreeDisabled = true;
                this.stepFourDisabled = true;
                this.stepFiveDisabled = false;
                break;
        }
    }

    handlerCheckNext() {
        switch (this.modelRequest.step) {
            case 1:
                this.btnOneDisabled = true;
                this.btnTwoDisabled = true;
                this.btnThreeDisabled = true;
                this.btnFourDisabled = true;
                this.btnFiveDisabled = true;
                break;
            case 2:
                this.btnOneDisabled = false;
                this.btnTwoDisabled = true;
                this.btnThreeDisabled = true;
                this.btnFourDisabled = true;
                this.btnFiveDisabled = true;
                break;
            case 3:
                this.btnOneDisabled = false;
                this.btnTwoDisabled = false;
                this.btnThreeDisabled = true;
                this.btnFourDisabled = true;
                this.btnFiveDisabled = true;
                break;
            case 4:
                this.btnOneDisabled = false;
                this.btnTwoDisabled = false;
                this.btnThreeDisabled = false;
                this.btnFourDisabled = true;
                this.btnFiveDisabled = true;
                break;
            case 4:
            case 5:
                this.btnOneDisabled = true;
                this.btnTwoDisabled = true;
                this.btnThreeDisabled = true;
                this.btnFourDisabled = true;
                this.btnFiveDisabled = false;
                break;
        }
    }

    setDatePicker() {
        const data = this.isNullOrUndefined(this.modelRequest.recordValidity) ? moment() : moment(this.modelRequest.recordValidity).format('DD/MM/yyyy');
        $('.js-daterangepicker').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            startDate: data,
            minYear: parseInt(moment().format('YYYY'), 10),
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.modelRequest.recordValidity = start.utcOffset(0, true).format();
        });
    }

    getid(id) {
        this.requestService.getId(id).subscribe({
            next: data => {
                this.modelRequest = data as Request;
                if (this.step) {
                    this.modelRequestResult = this.modelRequest;
                }
                if (this.modelRequest.status !== 'open' && !this.isNullOrUndefined(this.modelRequest.status)) {
                    this.stepOneDisabled = true;
                    this.stepTwoDisabled = true;
                    this.stepThreeDisabled = true;
                    this.stepFourDisabled = true;
                    this.stepFiveDisabled = true;
                }
            }, error: err => super.onError(err)
        });
    }

    request1() {
        const arrValidateFields = [
            {value: this.modelRequest.name, text: 'Nome do projeto*<br>'},
            {value: this.modelRequest.code, text: 'Código do modelo*<br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Preencha os campos corretamente: <br><br> ${stringError}`);
            return;
        }
        // this.loading.show();
        this.requestService.step('one', this.modelRequest).subscribe({
            next: data => {
                this.modelRequest = data as Request;
                this.actionConfirmAlert();
                // this.handlerNext(1);
                // this.router.navigate(['/register/' + this.modelRequest.id]);
                // setTimeout(() => {
                //     this.loading.hide();
                //     this.handlerConfirm();
                // }, 1000);
            }, error: err => super.onError(err)
        });
    }

    // REQUEST 2
    actionChannel() {
        this.enumService.getEnum('channel').subscribe({
            next: data => {
                this.listChannel = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    actionMeasure() {
        this.enumService.getEnum('measure').subscribe({
            next: data => {
                this.listMeasure = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    actionDispatched() {
        this.enumService.getEnum('dispatched').subscribe({
            next: data => {
                this.listDispatched = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    request2() {
        const arrValidateFields = [
            {value: this.modelRequest.motive, text: `${this.translate.instant('novocadastro.etapa2.input1')}, <br>`},
            {
                value: this.modelRequest.channelDistribution,
                text: `${this.translate.instant('novocadastro.etapa2.input2')}, <br>`
            },
            {
                value: this.modelRequest.unitMeasure,
                text: `${this.translate.instant('novocadastro.etapa2.input3')}, <br>`
            },
            {
                value: this.modelRequest.description,
                text: `${this.translate.instant('novocadastro.etapa2.input8')}, <br>`
            },
            {
                value: this.modelRequest.recordHolder,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.detentor')}, <br>`
            },
            {
                value: this.modelRequest.ministryRecord,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.registro')}, <br>`
            },
            {
                value: this.modelRequest.dispatchedBy,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.expedido')}, <br>`
            },
            {
                value: this.modelRequest.recordValidity,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.validade')}, <br>`
            },
            {
                value: this.modelRequest.manufacturer,
                text: `${this.translate.instant('novocadastro.etapa2.input7')}, <br>`
            },
            {value: this.modelRequest.dueDate, text: `${this.translate.instant('novocadastro.etapa2.input13')}. <br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Preencha os campos corretamente: <br><br> ${stringError}`);
            return;
        }
        // this.loading.show();
        this.requestService.step('two', this.modelRequest).subscribe({
            next: data => {
                this.modelRequest = data as Request;
                this.actionConfirmAlert();
                // this.handlerNext(2);
                // this.loading.hide();
                setTimeout(() => {
                    this.setDatePicker();
                }, 500);
                // this.handlerConfirm();
            }, error: err => super.onError(err)
        });
    }

    // REQUEST 3

    request3() {
        const arrValidateFields = [
            {value: this.modelRequest.motive, text: `${this.translate.instant('novocadastro.etapa2.input1')}, <br>`},
            {
                value: this.modelRequest.channelDistribution,
                text: `${this.translate.instant('novocadastro.etapa2.input2')}, <br>`
            },
            {
                value: this.modelRequest.unitMeasure,
                text: `${this.translate.instant('novocadastro.etapa2.input3')}, <br>`
            },
            {
                value: this.modelRequest.description,
                text: `${this.translate.instant('novocadastro.etapa2.input8')}, <br>`
            },
            {
                value: this.modelRequest.recordHolder,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.detentor')}, <br>`
            },
            {
                value: this.modelRequest.ministryRecord,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.registro')}, <br>`
            },
            {
                value: this.modelRequest.dispatchedBy,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.expedido')}, <br>`
            },
            {
                value: this.modelRequest.recordValidity,
                text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.validade')}, <br>`
            },
            {
                value: this.modelRequest.manufacturer,
                text: `${this.translate.instant('novocadastro.etapa2.input7')}, <br>`
            },
            {value: this.modelRequest.dueDate, text: `${this.translate.instant('novocadastro.etapa2.input13')}. <br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Preencha os campos corretamente: <br><br> ${stringError}`);
            return;
        }
        // this.loading.show();
        this.requestService.step('three', this.modelRequest).subscribe({
            next: data => {
                this.modelRequest = data as Request;
                this.modelRequestResult = this.modelRequest;
                this.modelRequest.unifications = [];
                // this.handlerNext(3);
                this.stepFourDisabled = false;
                this.actionConfirmAlert();
                this.actionLike();
                // this.loading.hide();
                // this.handlerConfirm();
            }, error: err => super.onError(err)
        });
    }

    // REQUEST 4

    actionLike() {
        this.requestService.like(this.modelRequest.id).subscribe({
            next: data => {
                this.listLike = data as Request[];
                this.modelRequest.unifications = [];
                this.listLike.forEach(x => {
                    this.modelRequest.unifications.push({isSelected: false, isDefault: false, request: x});
                });
            }, error: err => super.onError(err)
        });
    }

    getResult(id) {
        this.requestService.getId(id).subscribe({
            next: data => {
                this.modelRequestResult = data as Request;
            }, error: err => super.onError(err)
        });
    }

    radioChecked(id) {
        this.modelRequest.unifications.forEach(item => {
            if (item.request.id !== id) {
                item.isDefault = false;
                item.isSelected = false;
            } else {
                this.getResult(id);
                item.isDefault = true;
                item.isSelected = true;
            }
        });
    }

    radioCheckedDad() {
        if (!this.isNullOrUndefined(this.modelRequest.unifications)) {
            const result = this.modelRequest.unifications.filter(x => {
                return x.isDefault === true;
            });
            if (this.isNullOrUndefined(result)) {
                return true;
            } else {
                return false;
            }
        }
    }

    request4() {
        const arrValidateFields = [
            {value: this.modelRequest.ean, text: `EAN 13, <br>`},
            {value: this.modelRequest.dum, text: `DUM 14, <br>`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Preencha os campos corretamente: <br><br> ${stringError}`);
            return;
        }

        // this.loading.show();
        const list = [];
        if (!this.isNullOrUndefined(this.modelRequest.unifications)) {
            this.modelRequest.unifications.forEach(e => {
                if (e.isSelected) {
                    list.push(e);
                }
            });
        }

        this.modelRequest.unifications = list;

        if (this.isNullOrUndefined(this.modelRequest.unifications)) {
            this.modelRequest.type = 'simple';
        } else {
            this.modelRequest.type = 'unification';
        }

        // this.modelRequest.ean = this.modelRequest.ean.toString()
        // this.modelRequest.dum = this.modelRequest.dum.toString()

        this.requestService.step('four', this.modelRequest).subscribe({
            next: data => {
                this.modelRequest = data as Request;
                this.actionConfirmAlert();
                // this.handlerConfirm();
                // this.handlerNext(4);
                // this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    // REQUEST 5

    requestFinish(status) {
        if (status === 'approve') {
            const arrValidateFields = [
                {value: this.modelRequest.codeSap, text: `Código do SAP, <br>`},
                {value: this.modelRequest.descriptionFinish, text: `Descritivo, <br>`},
            ];

            const stringError = this.validateField(arrValidateFields);

            if (!super.isNullOrUndefined(stringError)) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), `Preencha os campos corretamente: <br><br> ${stringError}`);
                return;
            }
        }

        this.modelRequest.status = status;

        // this.loading.show();
        this.requestService.step('five', this.modelRequest).subscribe({
            next: data => {
                this.router.navigate(['/my-list']);
                setTimeout(() => {
                    // this.loading.hide();
                    this.handlerConfirm();
                }, 1000);
            }, error: err => super.onError(err)
        });
    }

    handlerMaximumCharaters(description) {
        if (!this.isNullOrUndefined(description)) {
            const result = 40 - description.length;
            return result;
        } else {
            return 40;
        }
    }

    handlerCharSubscr($event: any, n: number) {
        $($event.target).val($($event.target).val().substring(0, n).replace(/\D/g, ''));
    }

    actionConfirmAlert() {
        const profile = this.notificationService.getUserLocal().profile;
        let validate = true;

        switch (this.step) {
            case 1 :
                if (profile === 'local') {
                    validate = false;
                }
                break;
            case 2 :
                if (profile === 'ar_local') {
                    validate = false;
                }
                break;
            case 3 :
                if (profile === 'ar_inter') {
                    validate = false;
                }
                break;
            case 4 :
                if (profile === 'arts') {
                    validate = false;
                }
                break;
            case 5 :
                if (profile === 'register') {
                    validate = false;
                }
                break;
        }

        setTimeout(() => {
            // this.loading.hide();
            this.handlerConfirm();
        }, 1000);

        if (validate) {
            this.handlerNext(this.step);
            this.router.navigate(['/register/' + this.modelRequest.id]);
        } else {
            this.router.navigate(['/my-list']);
        }

    }
}
