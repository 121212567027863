import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from "../../../generated/graphql";

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    user: User = new User();

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService
    ) {
        super(router, translate);
    }

    ngOnInit(): void {

        this.userService.user.subscribe({
            next: data => {
                const a: any = data;
                this.user = a.getCurrentUser;
            }, error: err => super.onError(err)
        });
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }
}
