import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../service/request.service';
import {Notification} from '../../model/user/notification';
import {RequestFilter} from '../../model/request/request-filter';
import {RequestResult} from '../../model/request/request-result';
import {Enum} from '../../model/enum';
import {EnumService} from '../../service/enum.service';
import {UserService} from "../../service/user.service";


declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-my-list',
    templateUrl: './my-list.component.html',
    styleUrls: ['./my-list.component.scss']
})
export class MyListComponent extends BaseComponent implements OnInit, AfterViewInit {

    modelFilter: RequestFilter = new RequestFilter();
    modelResult: RequestResult = new RequestResult();
    listStatus = ['open', 'approve', 'disapprove'];
    listStep = [1, 2, 3, 4, 5];
    listChannel: Enum[] = [];
    listDispatched: Enum[] = [];

    constructor(public router: Router,
                public translate: TranslateService,
                public enumService: EnumService,
                public requestService: RequestService,
                public notificationService: UserService,
    ) {
        super(router, translate);
    }

    ngOnInit() {
        this.actionChannel();
        this.actionDispatched();

    }

    ngAfterViewInit() {
        this.setDatePicker();
        this.activeMyList();
    }

    activeMyList() {
        // this.loading.show();
        this.requestService.search(this.modelFilter, 1).subscribe({
            next: data => {
                this.modelResult = data as RequestResult;
                // this.loading.hide();
            }, error: err => super.onError(err)
        });
        this.notificationService.getNotification();
    }

    handlerLanguage(model) {
        switch (model) {
            case 'open':
                if (this.translate.currentLang === 'pt') {
                    return 'Aberto';
                } else {
                    return 'abierto';
                }
            case 'approve':
                if (this.translate.currentLang === 'pt') {
                    return 'Aprovado';
                } else {
                    return 'Aprobado';
                }
            case 'disapprove':
                if (this.translate.currentLang === 'pt') {
                    return 'Reprovado';
                } else {
                    return 'Desaprobado';
                }
            case 1:
                if (this.translate.currentLang === 'pt') {
                    return 'Comercial local';
                } else {
                    return 'Negocio local';
                }
            case 2:
                if (this.translate.currentLang === 'pt') {
                    return 'AR Local';
                } else {
                    return 'AR Local';
                }
            case 3:
                if (this.translate.currentLang === 'pt') {
                    return 'AR International';
                } else {
                    return 'AR Internacional';
                }
            case 4:
                if (this.translate.currentLang === 'pt') {
                    return 'Artes';
                } else {
                    return 'Letras';
                }
            case 5:
                if (this.translate.currentLang === 'pt') {
                    return 'Cadastro';
                } else {
                    return 'Registrarse';
                }
        }
    }

    actionChannel() {
        this.enumService.getEnum('channel').subscribe({
            next: data => {
                this.listChannel = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    actionDispatched() {
        this.enumService.getEnum('dispatched').subscribe({
            next: data => {
                this.listDispatched = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    setDatePicker() {
        $('.js-datepangepicker').daterangepicker({
            startDate: moment().subtract(15, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }


    actionSearch() {
        this.requestService.search(this.modelFilter, 1).subscribe({
            next: data => {
                this.modelResult = data as RequestResult;
                // this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionClear() {
        this.modelFilter = new RequestFilter();
        this.setDatePicker();
    }
}
