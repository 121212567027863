<div class="d-block d-xxl-flex">
    <div style="width: 100%;min-width: 60%" class="px-3">

        <div class="row mt-4 mb-3 px-3 px-md-0">
            <div class="col-2">
                <a href="javascript:$('back');" (click)="step !== 1 ? handlerBack(step - 1) : router.navigate(['/my-list'])">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                </a>
            </div>
            <div class="col-7 col-lg-8 text-center">
                <span class="header-title" *ngIf="!isEdit">{{'novocadastro.title' | translate}}</span>
                <span class="header-title" *ngIf="isEdit">{{'novocadastro.titleDetail' | translate}}</span>
            </div>
            <div class="col-3 col-lg-2">
                <span class="header-date">{{'novocadastro.criado' | translate}}
                    <strong *ngIf="!isEdit">{{createaAt | date: 'dd/MM/yyyy'}}</strong>
                    <strong *ngIf="isEdit">{{modelRequest.createdAt | date: 'dd/MM/yyyy'}}</strong></span>
            </div>
        </div>

        <div class="py-3 bg-beige rounded pb-5 px-4">
            <div class="nav_step shadow mx-auto mb-4"
                 [ngClass]="{'d-none': !(modelRequest.status == 'open' || isNullOrUndefined(modelRequest.status))}">
                <ul class="nav d-flex justify-content-between" id="myTab" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item active" id="step1-tab" data-toggle="tab" href="#step1"
                           role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step">1</span>
                            <div class="position_item" *ngIf="!stepOneDisabled">
                                <span class="position_item-stage">{{'minhalista.input8.title' | translate}}</span>
                                <span class="position_item-name">{{'novocadastro.etapa1.title' | translate}}</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepOneDisabled && !this.isNullOrUndefined(modelRequest.history[0])">
                                <span class="position_item-name">{{modelRequest.history[0].createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tab" data-toggle="tab" href="#step2" role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step">2</span>
                            <div class="position_item" *ngIf="!stepTwoDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR Local</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepTwoDisabled && !this.isNullOrUndefined(modelRequest.history[1])">
                                <span class="position_item-name">{{modelRequest.history[1].createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step3-tab" data-toggle="tab" href="#step3" role="tab"
                           aria-controls="step3" aria-selected="false">
                            <span class="step">3</span>
                            <div class="position_item" *ngIf="!stepThreeDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR International</span>
                            </div>
                            <div class="position_item"
                                 *ngIf="stepThreeDisabled && !this.isNullOrUndefined(modelRequest.history[2])">
                                <span class="position_item-name">{{modelRequest.history[2].createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step4-tab" data-toggle="tab" href="#step4" role="tab"
                           aria-controls="step4" aria-selected="false">
                            <span class="step">4</span>
                            <div class="position_item text-right" *ngIf="!stepFourDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Artes</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFourDisabled && !this.isNullOrUndefined(modelRequest.history[3])">
                                <span class="position_item-name">{{modelRequest.history[3].createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step5-tab" data-toggle="tab" href="#step5" role="tab"
                           aria-controls="step5" aria-selected="false">
                            <span class="step">5</span>
                            <div class="position_item text-right" *ngIf="!stepFiveDisabled">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Cadastro</span>
                            </div>
                            <div class="position_item text-right"
                                 *ngIf="stepFiveDisabled && !this.isNullOrUndefined(modelRequest.history[4])">
                                <span class="position_item-name">{{modelRequest.history[4].createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="nav_step-secondary shadow mx-auto mb-4"
                 [ngClass]="{'d-none': !(modelRequest.status !== 'open' && !isNullOrUndefined(modelRequest.status))}">
                <ul class="nav d-flex justify-content-between" id="myTabF" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item finish" id="step1-tabF" data-toggle="tab"
                           href="#step1"
                           role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step">1</span>
                            <div class="position_item">
                                <span class="position_item-stage">{{modelRequest.history[0]?.createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item finish" id="step2-tabF" data-toggle="tab"
                           href="#step2"
                           role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step">2</span>
                            <div class="position_item">
                                <span class="position_item-stage">{{modelRequest.history[1]?.createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class=" text-center">
                        <a class="nav-link nav_step-secondary-item finish" id="step3-tabF" data-toggle="tab"
                           href="#step3"
                           role="tab"
                           aria-controls="step3" aria-selected="false">
                            <span class="step">3</span>
                            <div class="position_item">
                                <span class="position_item-stage">{{modelRequest.history[2]?.createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item finish" id="step4-tabF" data-toggle="tab"
                           href="#step4"
                           role="tab"
                           aria-controls="step4" aria-selected="false">
                            <span class="step">4</span>
                            <div class="position_item">
                                <span class="position_item-stage">{{modelRequest.history[3]?.createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-secondary-item finish" id="step5-tabF" data-toggle="tab"
                           href="#step5" role="tab"
                           aria-controls="step5" aria-selected="false">
                            <span class="step">5</span>
                            <div class="position_item">
                                <span class="position_item-stage">{{modelRequest.history[4]?.createdAt | date: 'dd/MM/yyyy'}}</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="tab-content" id="myTabContent">
            <!--request-one-->
            <div class="tab-pane fade show active" id="step1" role="tabpanel" aria-labelledby="step1-tab">
                <div class="mx-auto p-4" style="max-width: 442px;">
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="input-material">
                                <input class="form-control" id="name" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.name" [disabled]="stepOneDisabled"/>
                                <label for="name" [ngClass]="{'disabled' : stepOneDisabled}">Nome do projeto*</label>
                            </div>
                            <div class="input-material">
                                <input class="form-control" id="code" maxlength="200" type="text" required
                                       [(ngModel)]="modelRequest.code" [disabled]="stepOneDisabled"/>
                                <label for="code" [ngClass]="{'disabled' : stepOneDisabled}">Código do modelo*</label>
                            </div>
                        </div>
                        <div class="col-12" style="margin-top: 14rem;">
                            <a href="javascript:void(0)" *ngIf="!stepOneDisabled" (click)="request1()"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>
                            <a href="javascript:void(0)" *ngIf="stepOneDisabled && !btnOneDisabled" (click)="handlerNext(1)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--request-two-->
            <div class="tab-pane fade" id="step2" role="tabpanel" aria-labelledby="step2-tab">
                <div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material">
                                <input class="form-control" id="request" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.motive"
                                       required [disabled]="stepTwoDisabled"/>
                                <label for="request"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa2.input1' | translate}}</label>
                            </div>
                            <div class="input-material">
                                <select id="channel" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.channelDistribution" [disabled]="stepTwoDisabled">
                                    <option disabled selected [ngValue]="null">Selecione</option>
                                    <option *ngFor="let c of listChannel" [value]="c.name">{{c.name}}</option>
                                </select>
                                <label for="channel" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input2' | translate}}</label>
                            </div>
                            <div class="input-material">
                                <select id="unity" class="form-control" name="unity"
                                        [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepTwoDisabled">
                                    <option disabled selected [ngValue]="null">Selecione</option>
                                    <option *ngFor="let c of listMeasure" [value]="c.name">{{c.name}}</option>
                                </select>
                                <label for="unity" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="text-left mb-2">
                                <span class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span>
                            </div>
                            <textarea class=" input-material" id="description" style="width: inherit; resize: none"
                                      rows="7" [(ngModel)]="modelRequest.description" [disabled]="stepTwoDisabled"
                                      maxlength="40" required></textarea>
                            <label for="description">
                                {{'novocadastro.maximo1' | translate}}
                                {{handlerMaximumCharaters(this.modelRequest.description)}}
                                {{'novocadastro.maximo2' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="register" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.recordHolder" [disabled]="stepTwoDisabled" required/>
                                <label for="register"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa4.secao2.card3.detentor' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="registerMinistry" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.ministryRecord" [disabled]="stepTwoDisabled" required/>
                                <label for="registerMinistry"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa4.secao2.card3.registro' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <select id="Dispatched" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.dispatchedBy" [disabled]="stepTwoDisabled">
                                    <option disabled selected [ngValue]="null">Selecione</option>
                                    <option *ngFor="let c of listDispatched" [value]="c.name">{{c.name}}</option>
                                </select>
                                <label for="Dispatched" [ngClass]="{'disabled' : stepTwoDisabled}" class="mb-0 mr-3">
                                    {{'novocadastro.etapa4.secao2.card3.expedido' | translate}}
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control js-daterangepicker" id="validity" type="text" maxlength="10"
                                       [disabled]="stepTwoDisabled" required/>
                                <label for="validity"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa4.secao2.card3.validade' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <!--<div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material" type="text" required/>
                                <label for="material">{{'novocadastro.etapa2.input6' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material2" type="text" required/>
                                <label for="material2">{{'novocadastro.etapa2.input6' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="group" type="text" required/>
                                <label for="group">{{'novocadastro.etapa2.input11' | translate}}</label>
                            </div>
                        </div>-->
                        <div class="col-xxl-12">
                            <div class="input-material">
                                <input class="form-control" id="Manufacturer" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.manufacturer" [disabled]="stepTwoDisabled" required/>
                                <label for="Manufacturer"
                                       [ngClass]="{'disabled' : stepTwoDisabled}">{{'novocadastro.etapa2.input7' | translate}}</label>
                            </div>
                        </div>
                        <!--<div class="col-xl-6 col-xxl-4">
                            <div class="input-material">
                                <select id="Hierarchy" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Hierarchy"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input12' | translate}}</label>
                            </div>
                        </div>-->
                        <div class="col-xxl-12">
                            <div class="input-material">
                                <select id="Deadline" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.dueDate" [disabled]="stepTwoDisabled">
                                    <option disabled selected [ngValue]="null">Selecione</option>
                                    <option *ngFor="let d of listDueDate" [value]="d">{{d}}
                                        <span *ngIf="d == 1">Mês</span>
                                        <span *ngIf="d !== 1">Meses</span>
                                    </option>
                                </select>
                                <label for="Deadline" [ngClass]="{'disabled' : stepTwoDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input13' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <a href="javascript:void(0)" *ngIf="!stepTwoDisabled" (click)="request2()"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>
                            <a href="javascript:void(0)" *ngIf="stepTwoDisabled && !btnTwoDisabled" (click)="handlerNext(2)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--request-three-->
            <div class="tab-pane fade" id="step3" role="tabpanel" aria-labelledby="step3-tab">
                <div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material">
                                <input class="form-control" id="request3" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.motive"
                                       required [disabled]="stepThreeDisabled"/>
                                <label for="request"
                                       [ngClass]="{'disabled' : stepThreeDisabled}">{{'novocadastro.etapa2.input1' | translate}}</label>
                            </div>
                            <div class="input-material">
                                <select id="channel3" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.channelDistribution" [disabled]="stepThreeDisabled">
                                    <option disabled selected value="undefined">Selecione</option>
                                    <option *ngFor="let c of listChannel" [value]="c.name">{{c.name}}</option>
                                </select>
                                <label for="channel" [ngClass]="{'disabled' : stepThreeDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input2' | translate}}</label>
                            </div>
                            <div class="input-material">
                                <select id="unity3" class="form-control" name="unity"
                                        [(ngModel)]="modelRequest.unitMeasure" [disabled]="stepThreeDisabled">
                                    <option disabled selected value="undefined">Selecione</option>
                                    <option *ngFor="let c of listMeasure" [value]="c.name">{{c.name}}</option>
                                </select>
                                <label for="unity" [ngClass]="{'disabled' : stepThreeDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input3' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="text-left mb-2">
                                <span class="text-secondary">{{'novocadastro.etapa2.input8' | translate}}</span>
                            </div>
                            <textarea class="input-material" id="descriptionStep3" style="width: inherit; resize: none"
                                      rows="7" [(ngModel)]="modelRequest.description" [disabled]="stepThreeDisabled"
                                      maxlength="40" required></textarea>
                            <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                   for="description">
                                {{'novocadastro.maximo1' | translate}}
                                {{handlerMaximumCharaters(this.modelRequest.description)}}
                                {{'novocadastro.maximo2' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="register3" type="text" maxlength="200"
                                       [disabled]="stepThreeDisabled"
                                       [(ngModel)]="modelRequest.recordHolder" required/>
                                <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                       for="register">{{'novocadastro.etapa4.secao2.card3.detentor' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="registerMinistry3" maxlength="200" type="text"
                                       [disabled]="stepThreeDisabled"
                                       [(ngModel)]="modelRequest.ministryRecord" required/>
                                <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                       for="registerMinistry">{{'novocadastro.etapa4.secao2.card3.registro' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <select id="Dispatched3" class="form-control" name="type" [disabled]="stepThreeDisabled"
                                        [(ngModel)]="modelRequest.dispatchedBy">
                                    <option disabled selected value="undefined">Selecione</option>
                                    <option *ngFor="let c of listDispatched" [value]="c.name">{{c.name}}</option>
                                </select>
                                <label [ngClass]="{'disabled' : stepThreeDisabled}" for="Dispatched" class="mb-0 mr-3">
                                    {{'novocadastro.etapa4.secao2.card3.expedido' | translate}}
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control js-daterangepicker" id="validity3" type="text" maxlength="10"
                                       [disabled]="stepThreeDisabled" required/>
                                <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                       for="validity">{{'novocadastro.etapa4.secao2.card3.validade' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <!--<div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material" type="text" required/>
                                <label for="material">{{'novocadastro.etapa2.input6' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material2" type="text" required/>
                                <label for="material2">{{'novocadastro.etapa2.input6' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="group" type="text" required/>
                                <label for="group">{{'novocadastro.etapa2.input11' | translate}}</label>
                            </div>
                        </div>-->
                        <div class="col-xxl-12">
                            <div class="input-material">
                                <input class="form-control" id="Manufacturer3" type="text" maxlength="200"
                                       [(ngModel)]="modelRequest.manufacturer" [disabled]="stepThreeDisabled" required/>
                                <label [ngClass]="{'disabled' : stepThreeDisabled}"
                                       for="Manufacturer">{{'novocadastro.etapa2.input7' | translate}}</label>
                            </div>
                        </div>
                        <!--<div class="col-xl-6 col-xxl-4">
                            <div class="input-material">
                                <select id="Hierarchy" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Hierarchy"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input12' | translate}}</label>
                            </div>
                        </div>-->
                        <div class="col-xxl-12">
                            <div class="input-material">
                                <select id="Deadline3" class="form-control" name="type"
                                        [(ngModel)]="modelRequest.dueDate" [disabled]="stepThreeDisabled">
                                    <option disabled selected [ngValue]="null">Selecione</option>
                                    <option *ngFor="let d of listDueDate" [value]="d">{{d}}
                                        <span *ngIf="d == 1">Mês</span>
                                        <span *ngIf="d !== 1">Meses</span>
                                    </option>
                                </select>
                                <label for="Deadline" [ngClass]="{'disabled' : stepThreeDisabled}"
                                       class="mb-0 mr-3">{{'novocadastro.etapa2.input13' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <a href="javascript:void(0)" (click)="request3()" *ngIf="!stepThreeDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>
                            <a href="javascript:void(0)" *ngIf="stepThreeDisabled && !btnThreeDisabled" (click)="handlerNext(3)"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--request-four-->
            <div class="tab-pane fade" id="step4" role="tabpanel" aria-labelledby="step4-tab">
                <div class="row" *ngIf="!stepFourDisabled && !isNullOrUndefined(listLike)">
                    <div class="col-12">
                        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none">
                            <div class="row p-3">
                                <span class="w-100 text-primary size-xm border-bottom mt-2 mx-4 pb-2">{{'novocadastro.etapa4.subtitle' | translate}}</span>
                                <div class="col-12 pl-4">
                                    <div class="row">
                                        <span class="px-4 mt-3 mb-4">
                                            {{'novocadastro.etapa4.text' | translate}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card border-0 p-2 mt-3 pl-sm-3">
                                        <div class="row">
                                            <div class="col-sm-1 d-flex justify-content-center align-items-center mb-3 mb-sm-0">
                                                <div class="d-block text-center">
                                                    <input class="input_radio" type="radio" id="item"
                                                           name="country" [checked]="radioCheckedDad()"
                                                           (change)="radioChecked('')">
                                                    <label for="item">
                                                        <span *ngIf="radioCheckedDad()">Principal</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col d-flex align-items-center justify-content-center">
                                                <img style="width: 42px;" class="img-fluid"
                                                     src="assets/images/country/{{handlerFlagImage(modelRequest.country)}}">
                                            </div>
                                            <div class="col-sm-5 ml-0 mt-3 mt-sm-0">
                                                <div class="row">
                                                    <span class="col-12 text-gray-text">{{modelRequest.country}}</span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro.etapa4.card1.formula' | translate}}
                                                        <strong>{{modelRequest.description}}</strong></span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro.etapa4.card1.prazo' | translate}}
                                                        <strong>{{modelRequest.dueDate}} meses</strong></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                <div class="row text-center">
                                                    <span class="col-12 text-gray-text size-xs text-right d-flex
                                                    align-items-center justify-content-center">
                                                       {{'novocadastro.etapa4.card1.prenchido' | translate}}
                                                        <br> {{'novocadastro.etapa4.card1.etapa' | translate}}
                                                        <span class="d-flex justify-content-center size-xm ml-2
                                                        align-items-center bg-warning rounded-circle mr-2"
                                                              style="width: 25px;height: 25px;">
                                                            <strong>{{modelRequest.step}}</strong>
                                                        </span>
                                                    </span>
                                                    <span class="col-12 text-primary">
                                                        <strong>{{'novocadastro.etapa3.title' | translate}}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>

                                <div class="col-12">
                                    <div class="card border-0 p-2 mt-3 pl-sm-3"
                                         *ngFor="let l of modelRequest.unifications; let i = index;">
                                        <div class="row">
                                            <div class="col-sm-1 d-flex justify-content-center align-items-center mb-3 mb-sm-0">
                                                <div class="d-block text-center">
                                                    <input class="input_radio" type="radio" id="item_{{i}}"
                                                           name="country" [checked]="l.isDefault"
                                                           (change)="radioChecked(l.request.id)">
                                                    <label for="item_{{i}}">
                                                        <span *ngIf="l.isDefault">Principal</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col d-flex align-items-center justify-content-center">
                                                <img style="width: 42px;" class="img-fluid"
                                                     src="assets/images/country/{{handlerFlagImage(l.request.country)}}">
                                            </div>
                                            <div class="col-sm-5 ml-0 mt-3 mt-sm-0">
                                                <div class="row">
                                                    <span class="col-12 text-gray-text">{{l.request.country}}</span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro.etapa4.card1.formula' | translate}}
                                                        <strong>{{l.request.description}}</strong></span>
                                                    <span class="col-12 text-gray-text">{{'novocadastro.etapa4.card1.prazo' | translate}}
                                                        <strong>{{l.request.dueDate}} meses</strong></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                <div class="row text-center">
                                                    <span class="col-12 text-gray-text size-xs text-right d-flex
                                                    align-items-center justify-content-center">
                                                       {{'novocadastro.etapa4.card1.prenchido' | translate}}
                                                        <br> {{'novocadastro.etapa4.card1.etapa' | translate}}
                                                        <span class="d-flex justify-content-center size-xm ml-2
                                                        align-items-center bg-warning rounded-circle mr-2"
                                                              style="width: 25px;height: 25px;">
                                                            <strong>{{l.request.step}}</strong>
                                                        </span>
                                                    </span>
                                                    <span class="col-12 text-primary">
                                                        <strong>{{'novocadastro.etapa3.title' | translate}}</strong>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-1 d-flex justify-content-center mt-3"
                                                 style="height: 29px;">
                                                <label class="input_check">
                                                    <input type="checkbox" id="itemCheck_{{i}}" name="country"
                                                           value="item_{{i}}" [checked]="l.isSelected"
                                                           [disabled]="l.isDefault"
                                                           [(ngModel)]="l.isSelected">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-auto p-4" style="max-width: 442px;">
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="input-material">
                                <input class="form-control" id="EAN" type="text"
                                       (change)="handlerCharSubscr($event, 13)" (keyup)="handlerCharSubscr($event, 13)"
                                       maxlength="13"
                                       [(ngModel)]="modelRequest.ean"
                                       [disabled]="stepFourDisabled"
                                       required/>
                                <label for="EAN" [ngClass]="{'disabled': stepFourDisabled}">EAN 13</label>
                            </div>
                            <div class="input-material">
                                <input class="form-control" id="DUM" type="text"
                                       (change)="handlerCharSubscr($event, 14)" (keyup)="handlerCharSubscr($event, 14)"
                                       maxlength="14"
                                       [(ngModel)]="modelRequest.dum"
                                       required [disabled]="stepFourDisabled"/>
                                <label for="DUM" [ngClass]="{'disabled': stepFourDisabled}">DUM 14</label>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 1rem;">
                    <div class="row">
                        <span style="margin-bottom: 2rem;" *ngIf="!stepFourDisabled">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo.
                    </span>
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <a href="javascript:void(0)" (click)="request4()" *ngIf="!stepFourDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.enviar' | translate}}</a>
                            <a href="javascript:void(0)" (click)="handlerNext(4)" *ngIf="stepFourDisabled && !btnFourDisabled"
                               class="btn btn-block btn-warning"
                               type="button">{{'novocadastro.next' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--request-five-->
            <div class="tab-pane fade" id="step5" role="tabpanel" aria-labelledby="step5-tab">
                <div class="row mt-4">
                    <div class="col-md-7">
                        <div class="px-3 py-4" style="max-height: 464px;overflow: auto;">
                            <div class="card bg-warning border-0 shadow-none">
                                <div class="row p-3">
                                    <strong class="col size-md">{{'novocadastro.etapa4.secao2.card1.criado' | translate}}</strong>
                                    <span class="col text-right size-md">{{modelRequest.createdAt | date: 'dd/MM/yyyy'}}</span>
                                    <span class="col-12 size-xm pl-4">{{modelRequest.createdBy?.name}}</span>
                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">{{'novocadastro.etapa4.secao2.card2.title' | translate}}</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">{{'novocadastro.etapa4.secao2.card2.nome' | translate}}
                                                <span class="text-primary">{{modelRequest.name}}</span>
                                            </span>
                                            <span class="col-12 mt-2">Código:
                                                <span class="text-primary">{{modelRequest.code}}</span>
                                            </span>
                                            <div class="col-12 mt-2">
                                                <img class="mr-2" width="30"
                                                     src="assets/images/country/{{handlerFlagImage(modelRequest.country)}}"
                                                     alt="country"/>
                                                <span>{{'novocadastro.etapa4.secao2.card2.pais' | translate}}
                                                    <span class="text-primary">{{modelRequest.country}}</span>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">AR Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">Motivo da solicitação: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.motive}}</span>
                                            <span class="col-12 mt-2">Canal de distribuição: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.channelDistribution}}</span>
                                            <span class="col-12 mt-2">Descrição: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.description}}</span>
                                            <span class="col-12 mt-2">Unidade de medida básica: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.unitMeasure}}</span>
                                            <span class="col-12 mt-2">Nome do detentor do registro: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordHolder}}</span>
                                            <span class="col-12 mt-2">Expedido por: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.dispatchedBy}}</span>
                                            <span class="col-12 mt-2">Registro Ministério da Saúde: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.ministryRecord}}</span>
                                            <span class="col-12 mt-2">Validade do registro: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordValidity | date: 'dd/MM/yyyy'}}</span>
                                            <span class="col-12 mt-2">Fabricante para comprar produto ou Subcontratação: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.manufacturer}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Artes</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">EAN 13:
                                                <span class="text-primary">{{modelRequest.ean}}</span>
                                            </span>
                                            <span class="col-12 mt-2">DUM 14:
                                                <span class="text-primary">{{modelRequest.dum}}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <strong class="col-12 mt-3 mb-2">Cadastros Unificados</strong>
                                    <div class="col-12">
                                        <div class="card p-2 pl-4 mt-3 pl-sm-3"
                                             *ngFor="let u of modelRequest.group">
                                            <div class="row">
                                                <div class="col d-flex align-items-center justify-content-center pr-2">
                                                    <img style="width: 42px;" class="img-fluid"
                                                         src="assets/images/country/{{handlerFlagImage(u.country)}}">
                                                </div>
                                                <div class="col-sm-6 ml-0 pl-0 mt-3 mt-sm-0">
                                                    <div class="row">
                                                        <span class="col-12 text-gray-text">{{u.country}}</span>
                                                        <span class="col-12 text-gray-text">Fórmula: <strong>{{u.description}}</strong></span>
                                                        <span class="col-12 text-gray-text">Prazo de validade:
                                                            <strong>{{u.dueDate}} meses</strong></span>
                                                    </div>

                                                </div>
                                                <div class="col-sm-4 d-flex align-items-center justify-content-center justify-content-lg-end mt-4 mt-sm-0">
                                                    <div class="row text-center">
                                                        <span class="col-12 text-gray-text size-xs">Preenchido até</span>
                                                        <span class="col-12 d-flex align-items-center justify-content-center text-primary">
                                                            <span class="d-flex justify-content-center align-items-center bg-warning rounded-circle mr-2"
                                                                  style="width: 25px;height: 25px;">
                                                                <strong>{{u.step}}</strong>
                                                            </span>
                                                            <strong>AR Interinacional</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="pl-5 my-4 pt-2 pr-5 border-left border-gray-text">
                            <div class="bg-success text-white rounded d-flex justify-content-center px-2 mb-2"
                                 *ngIf="modelRequest.status == 'approve'" style="width: fit-content">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                <span class="ml-1">Concluído</span>
                            </div>

                            <div class="bg-danger text-white rounded d-flex justify-content-center px-2 mb-2"
                                 style="width: fit-content" *ngIf="modelRequest.status == 'disapprove'">
                                <svg-icon src="assets/svg/close.svg"></svg-icon>
                                <span class="ml-1">Reprovado</span>
                            </div>
                            <ng-container *ngIf="modelRequest.status !== 'disapprove'">
                                <div class="input-material">
                                    <input class="form-control" id="SAP" type="text" maxlength="10"
                                           [disabled]="stepFiveDisabled"
                                           [(ngModel)]="modelRequest.codeSap"
                                           required/>
                                    <label for="SAP" [ngClass]="{'disabled': stepFiveDisabled}">Código do SAP</label>
                                </div>
                                <div class="text-left mb-2">
                                    <span class="text-secondary">Descritivo</span>
                                </div>
                                <textarea class="input-material mb-0" id="description3"
                                          style="width: 100%; resize: none"
                                          rows="7" [(ngModel)]="modelRequest.descriptionFinish"
                                          [disabled]="stepFiveDisabled"
                                          maxlength="40" required></textarea>
                                <label class=" w-100 text-right" for="description3"
                                       [ngClass]="{'disabled': stepFiveDisabled}">
                                    {{'novocadastro.maximo1' | translate}}
                                    {{handlerMaximumCharaters(this.modelRequest.descriptionFinish)}}
                                    {{'novocadastro.maximo2' | translate}}</label>
                            </ng-container>
                            <ng-container
                                    *ngIf="modelRequest.status == 'open' || isNullOrUndefined(modelRequest.status)">
                                <a href="javascript:void(0)" (click)="requestFinish('approve')"
                                   class="btn btn-block btn-warning mt-5"
                                   type="button">Aprovar</a>
                                <a href="javascript:void(0)" (click)="requestFinish('disapprove')"
                                   class="btn btn-block btn-outline-primary mt-3"
                                   type="button">Reprovar</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-beige px-3 py-4" *ngIf="step == 3 || step == 4"
         style="width: 100%;min-width: 40%;overflow: auto;min-height: 709px;">
        <div class="card bg-warning border-0 shadow-none">
            <div class="row p-3">
                <strong class="col size-md">Criado por</strong>
                <span class="col text-right size-md">{{modelRequest.createdAt | date: 'dd/MM/yyyy'}}</span>
                <span class="col-12 size-xm pl-4">{{modelRequest.createdBy?.name}}</span>
            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">Comercial Local</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">Nome do Projeto:
                        <span class="text-primary">{{modelRequestResult.name}}</span>
                        </span>
                        <span class="col-12 mt-2">Código:
                            <span class="text-primary">{{modelRequestResult.code}}</span>
                        </span>
                        <div class="col-12 mt-2">
                            <img class="mr-2" width="30"
                                 src="assets/images/country/{{handlerFlagImage(modelRequestResult.country)}}"
                                 alt="country"/>
                            <span>País de Origem:
                                <span class="text-primary">{{modelRequestResult.country}}</span>
                            </span>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">AR Local</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">Motivo da solicitação: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.motive}}</span>
                        <span class="col-12 mt-2">Canal de distribuição: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.channelDistribution}}</span>
                        <span class="col-12 mt-2">Descrição: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.description}}</span>
                        <span class="col-12 mt-2">Unidade de medida básica: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">{{modelRequestResult.unitMeasure}}</span>
                        <span class="col-12 mt-2">Nome do detentor do registro: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordHolder}}</span>
                        <span class="col-12 mt-2">Expedido por: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.dispatchedBy}}</span>
                        <span class="col-12 mt-2">Registro Ministério da Saúde: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.ministryRecord}}</span>
                        <span class="col-12 mt-2">Validade do registro: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.recordValidity | date: 'dd/MM/yyyy'}}</span>
                        <span class="col-12 mt-2">Fabricante para comprar produto ou Subcontratação: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">{{modelRequestResult.manufacturer}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
