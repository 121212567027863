<div class="page-header">
    <div class="container justify-content-between">
        <a [routerLink]="['/solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <div class="page-header__number">Nº <strong>59745</strong></div>
        <h2 class="page-header__title"><strong>{{'bolsaestudos'|translate}}</strong></h2>
        <div class="page-header__date">{{'criado' | translate}} <strong>02/12/2021</strong></div>
    </div>
</div>
<div class="page-content">
    <div class="container">
        <div class="d-flex justify-content-between align-items-center mt-3 mt-md-5">
            <h2 class="page-content__section first">
                <strong>1</strong>
                <span>{{'colaborador'|translate}}</span>
            </h2>
        </div>
        <div class="page-content__details mt-0 mt-md-4 mb-3 light">
            <div class="row">
                <div class="col-9 col-sm-6 col-md-3">
                    <h4 class="title">{{'nome'|translate}}</h4>
                    <p class="text">Camila de Camargo</p>
                </div>
                <div class="col-3 col-sm-6 col-md-2">
                    <h4 class="title">RE</h4>
                    <p class="text">12345</p>
                </div>
                <div class="col-3 col-md-2">
                    <h4 class="title">{{'unidade'|translate}}</h4>
                    <p class="text">Itapevi</p>
                </div>
                <div class="col-6 col-md-3">
                    <h4 class="title">{{'departamento/setor'|translate}}</h4>
                    <p class="text">{{'comercial'|translate}}</p>
                </div>
                <div class="col-3 col-md-2">
                    <h4 class="title">Ramal</h4>
                    <p class="text">235</p>
                </div>
            </div>
        </div>
        <form action="#" class="page-content__form light mt-3 mb-md-5">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="input-material">
                        <input class="form-control" type="text" required />
                        <label>{{'cargoatual'|translate}}</label>
                    </div>
                </div>
                <div class="col-6 col-md-4">
                    <div class="input-material">
                        <select class="form-control" required>
                            <option disabled value hidden selected></option>
                            <option>1000</option>
                            <option>2000</option>
                        </select>
                        <label>{{'bolsa.faixa' | translate}}</label>
                    </div>
                </div>
                <div class="col-6 col-md-4">
                    <div class="input-material">
                        <input class="form-control" type="text" required />
                        <label>
                            <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                            <span>{{'dataadmissao'|translate}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="input-material">
                        <input class="form-control" type="text" required />
                        <label>{{'centrocusto'|translate}}</label>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="input-material">
                        <input class="form-control" type="text" required />
                        <label>{{'gerarea'|translate}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="input-material">
                        <textarea class="input-material form-control" required></textarea>
                        <label>{{'resumodasprincipais'|translate}} </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
