<div class="d-flex bg-warning align-items-center pl-4 pt-4">
    <h1 class="title ">PA - LATAM |</h1> <span
        class="subTitle text-primary ml-2">{{'minhalista.title' | translate}}</span>
</div>

<div class="bg-gray w-100 pt-3 px-3 px-sm-5">
    <div class="row">
        <div class="col-md-10 pr-md-4">
            <div class="row">
                <div class="col-xxl-8">
                    <div class="row">
                        <div class="col-sm-6 col-lg-4">
                            <div class="input-material">
                                <input id="name" class="form-control" type="text" [(ngModel)]="modelFilter.name"
                                       required/>
                                <label for="name">{{'minhalista.input1' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="input-material">
                                <input id="code" class="form-control" type="text" [(ngModel)]="modelFilter.code"
                                       required/>
                                <label for="code">{{'minhalista.input2' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="input-material">
                                <select id="status" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option *ngFor="let d of listStatus" [value]="d">{{handlerLanguage(d)}}</option>
                                </select>
                                <label for="status" class="mb-0 mr-3">Status</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input-material">
                                <select id="channel" class="form-control" name="type" [(ngModel)]="modelFilter.channel">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option *ngFor="let d of listChannel" [value]="d.name">{{d.name}}</option>
                                </select>
                                <label for="channel" class="mb-0 mr-3">{{'minhalista.input5.title' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input-material">
                                <select id="dispatched" class="form-control" name="type"
                                        [(ngModel)]="modelFilter.dipatchedBy">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option *ngFor="let d of listDispatched" [value]="d.name">{{d.name}}</option>
                                </select>
                                <label for="dispatched"
                                       class="mb-0 mr-3">{{'minhalista.input6.title' | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <!-- <span class="col-xs-2 mt-3">{{'minhalista.periodo' | translate}}</span>-->
                                <div class="col-xs-12">
                                    <div class="input-material">
                                        <input id="start" class="form-control js-datepangepicker" type="text"
                                               required/>
                                        <label for="start">{{'minhalista.input4' | translate}}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <select id="stage" class="form-control" name="type" [(ngModel)]="modelFilter.step">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option *ngFor="let d of listStep" [value]="d">{{handlerLanguage(d)}}</option>
                                </select>
                                <label for="stage" class="mb-0 mr-3">{{'minhalista.input8.title' | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 border-left mb-4 pl-3 pl-md-4">
            <a href="javascript:void(0)" (click)="actionClear()"
               class="btn btn-block btn-outline-primary"
               type="button">{{'minhalista.limpar' | translate}}</a>
            <a href="javascript:void(0)" (click)="actionSearch()"
               class="btn btn-block text-warning btn-primary"
               type="button">{{'minhalista.buscar' | translate}}</a>
        </div>
    </div>

</div>
<div class="mt-4" style="max-width: 1484px; margin: auto;">
    <div class="text-center mt-5" *ngIf="isNullOrUndefined(modelResult.results)">
        <span>Sem Resultados</span>
    </div>
    <div *ngFor="let r of modelResult.results" class="row mx-5 py-4 border-bottom" [routerLink]="'/register/'+ r.id">
        <div class="col">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="row">
                    <span class="dash_card_title size-xm">
                        {{r.name}}
                    </span>
                    </div>
                    <div class="row">
                        <span>{{'dashboard.card2.card.codigo' | translate}}: {{r.code}}</span>

                    </div>
                </div>
                <div class="col-12 col-md-3 request_steps">
                    <div class="d-flex justify-content-center">
                        <span class="unificado mb-1" *ngIf="r.type !== 'simple'">
                            <svg-icon src="assets/svg/unified.svg"></svg-icon>
                            {{'dashboard.card2.card.unificado' | translate}}
                        </span>
                        <span class="unificado_simples mb-1" *ngIf="r.type == 'simple'">
                                <svg-icon src="assets/svg/simple.svg"></svg-icon>
                            {{'dashboard.card2.card.simples' | translate}}
                            </span>
                    </div>

                    <div class="flags justify-content-center">
                        <div class="flags justify-content-center">
                            <img class="flags_thumb" *ngIf="r.type == 'simple'"
                                 src="assets/images/country/{{handlerFlagImage(r.country)}}" alt="country"/>
                            <ng-container *ngIf="r.type != 'simple'">
                                <img class="flags_thumb" src="assets/images/country/{{handlerFlagImage(r.country)}}"
                                     alt="country"/>
                                <div *ngFor="let g of r.group; let i = index">
                                    <img class="flags_thumb" *ngIf="i < 3"
                                         src="assets/images/country/{{handlerFlagImage(g.country)}}" alt="country"/>
                                </div>
                                <span class="p-0 m-0" *ngIf="r.group?.length > 3">+{{r.group?.length - 3}}</span>
                            </ng-container>


                            <div class="flag_extended" *ngIf="r.group.length > 3">
                                <ul>
                                    <li>
                                        <div class="flag_extended_inside">
                                            <div class="flag_extended_inside_flag">
                                                <img class="flags_thumb" *ngFor="let g of r.group"
                                                     src="assets/images/country/{{handlerFlagImage(g.country)}}"
                                                     alt="country"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="r.status == 'open'" class="col-12 col-md-5 request_steps">
                    <div class="d-flex justify-content-between">
                        <span class="position-relative" *ngFor="let i of [1,2,3,4,5]"
                              [ngClass]="{'font-weight-bold':r.step == i}">
                            {{i}}
                            <span
                                    style="left: 0;top: 22px;"
                                    class="position-absolute font-weight-normal">{{r.history[i - 1]?.createdAt | date: 'dd/mm'}}</span>
                        </span>
                    </div>
                    <div class="progress request_steps_barFull">
                        <div class="progress-bar request_steps_bar"
                             role="progressbar"
                             style="{{handlerStepBar(r.step)}}" aria-valuenow="15"
                             aria-valuemin="0" aria-valuemax="200">
                        </div>
                    </div>
                </div>
                <div *ngIf="r.status == 'disapprove'" class="col-12 col-md-5 request_steps">
                    <div class="row">
                        <div class="col"></div>
                        <div class="d-flex align-items-center ml-auto">
                            <div class="bg-danger text-white rounded d-flex justify-content-center px-2">
                                <svg-icon src="assets/svg/close.svg"></svg-icon>
                                <span class="ml-1">{{'minhalista.reprovado' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="r.status == 'approve'" class="col-12 col-md-5 request_steps">
                    <div class="row">
                        <div class="col">
                            <span>{{'dashboard.card2.card.codigo' | translate}} SAP:<br></span>
                            <strong>{{r.codeSap}}</strong>
                        </div>
                        <div class="d-flex align-items-center ml-auto">
                            <div class="bg-success text-white rounded d-flex justify-content-center px-2">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                <span class="ml-1">{{'minhalista.concluido' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--        <div class="col mx-sm-3 d-flex justify-content-end align-items-center">-->
        <!--            <svg-icon src="assets/svg/menu.svg"></svg-icon>-->
        <!--        </div>-->
    </div>
</div>

