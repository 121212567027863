import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalhe-da-bolsa',
  templateUrl: './detalhe-da-bolsa.component.html',
  styleUrls: ['./detalhe-da-bolsa.component.scss']
})
export class DetalheDaBolsaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
