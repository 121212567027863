export class RequestFilter {

    name: string;
    code: string;
    status: string;
    start: Date;
    end: Date;
    channel: string;
    step: number;
    dipatchedBy: string;

    constructor() {
        this.status = null;
        this.channel = null;
        this.dipatchedBy = null;
        this.step = null;


    }
}
