<div class="page-header full bordered">
    <div class="container justify-content-between">
        <h2 class="page-header__title single">{{'bolsa.faixa' | translate}}</h2>
    </div>
</div>

<div class="page-content full">
    <div class="container">
        <div class="page-content__list mt-4 mb-3">

            <div class="table-responsive">
                <table class="table" cellpadding="0" cellspacing="0" border="0">
                    <thead>
                    <tr>
                        <th scope="col">Valor</th>
                        <th scope="col">{{'criado' | translate}}</th>
                        <th scope="col">{{'atualizado' | translate}}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="false">
                        <td colspan="7">
                            <p class="text-center py-5">Nenhum resultado encontrado.</p>
                        </td>
                    </tr>
                    <tr *ngFor="let x of listSalaryRange">
                        <td>{{x.value | translate}}</td>
                        <td>{{x.createdAt | date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</td>
                        <td>{{x.updatedAt | date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</td>
                        <td class="text-center">
                            <button (click)="onDetail(x)">
                                <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalSalary" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">{{'bolsa.faixa' | translate}}</h3>
                <a href="javascript:void('');"
                   class="btn" type="button" (click)="closeModal('modalSalary')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <!--NAME-->
                        <div class="col-12">
                            <div class="row">
                                <dl class="col">
                                    <dt>{{'criado' | translate}}</dt>
                                    <dd>{{salaryRange.createdAt| date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{'atualizado' | translate}}</dt>
                                    <dd>{{salaryRange.updatedAt| date:'dd/MM/yyyy - HH:mm':'UTC+0'}}</dd>
                                </dl>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <input id="linkPolicyDocument" class="form-control" type="text"
                                       required [(ngModel)]="salaryRange.value" maxlength="150"/>
                                <label for="linkPolicyDocument">Valor*</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button (click)="onSave()" class="btn btn-block btn-warning mt-3">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
