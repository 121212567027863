<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>
        <li class="nav-item  d-lg-none">
            <a href="javascript:void(0);" [routerLink]="['/']" class="nav-link text-white"
               style="height: initial; padding: 20px 7.5px"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src=""></svg-icon></span>
                <span class="text-center">
          <!--<span class="lbl sidebar-title">
              BIO
          </span>-->
          <span class="d-block d-lg-none header__user mb-0">
            <span>{{'headerSalutation' | translate}}, <strong>{{user?.name}}</strong></span>
            <img src="https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg"
                 class="profile_photo"/>
          </span>
        </span>
            </a>
        </li>
        <li class="nav-item" [ngClass]="{'active':this.router.url ==''}" *ngIf="user?.profile != 'consultant' ">
            <a href="javascript:void('Requests');" [routerLink]="['/solicitacoes']" class="nav-link btn-request"
               (click)="actionCloseCollapse()">
                <span class="lbl">
                    <svg-icon class="mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                    {{'sidebar.novaSolicitacoes' | translate}}
                </span>
            </a>
        </li>

        <li class="nav-item" [ngClass]="{'active':this.router.url =='/'}" *ngIf="user?.profile !== 'user'">
            <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link text-white p-3"
               (click)="actionCloseCollapse()">
                <span class="lbl">Dashboard</span>
            </a>
        </li>

        <li class="nav-item" [ngClass]="{'active':this.router.url =='/lista-de-solicitacoes'}">
            <a href="javascript:void('Requests');" [routerLink]="['/lista-de-solicitacoes']"
               class="nav-link p-3 text-white"
               (click)="actionCloseCollapse()">
                <span class="lbl">{{'sidebar.solicitacoes' | translate}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="user?.profile == 'admin'"
            [ngClass]="{'active':getRoute() === '/user'||getRoute() === '/config-salary'||getRoute() === '/config-politic'}">
            <a href="javascript:void('Nota');" class="nav-link p-3 text-white" style="gap: 20px" data-toggle="collapse"
               data-target="#settings" aria-expanded="false" aria-controls="settings">
                <span class="lbl">{{'settings' | translate}}</span>
            </a>
            <div class="collapse" id="settings" data-parent="#accordionExample"
                 [ngClass]="{'show':getRoute() === '/user'||getRoute() === '/config-politic'||getRoute() === '/config-salary'}">

                <a href="javascript:void(0);" [routerLink]="['/user']"
                   (click)="actionCloseCollapse()" [ngClass]="{'active':router.url === '/user'}"
                   class="nav-link p-3 text-white">
                    <span class="lbl">{{'sidebar.user' | translate}}</span>
                </a>

                <a href="javascript:void(0);" [routerLink]="['/config-politic']"
                   (click)="actionCloseCollapse()" [ngClass]="{'active':router.url === '/config-politic'}"
                   class="nav-link p-3 text-white">
                    <span class="lbl">{{'politic' | translate}}</span>
                </a>

                <a href="javascript:void(0);" [routerLink]="['/config-salary']"
                   [ngClass]="{'active':getRoute() === '/config-salary'}"
                   (click)="actionCloseCollapse()" class="nav-link p-3 text-white">
                    <span class="lbl">{{'bolsa.faixa' | translate}}</span>
                </a>

            </div>
        </li>

    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" [routerLink]="'/logout'">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
