import {Request} from './request';

export class RequestResult {

    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Request[];

    constructor() {
        this.results = [];
    }
}
