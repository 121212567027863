<ngx-spinner
        bdColor="rgba(3,46,88,1)"
        size="medium"
        color="#fff"
        type="ball-spin"
>
</ngx-spinner>

<app-header></app-header>

<div class="app-body">

    <app-sidebar></app-sidebar>
    <div class="main px-0 pb-0">
        <router-outlet></router-outlet>
    </div>

    <!--Modal-->
    <div class="modal-custom modal fade" id="modalNoteResume" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-body modal-body--small">
                    <div class="row flex-column">
                        <div class="text-center mx-auto">
                            <svg-icon src="assets/svg/confirm.svg"></svg-icon>
                        </div>
                        <div class="text-center mx-auto font-weight-bold text-primary mt-5 size-xl"
                             style="max-width: 369px;line-height: 0.9;">
                            Ok, seus dados foram salvos com sucesso.
                        </div>
                        <div class="text-center mx-auto size-xm text-gray-text mt-4" style="max-width: 404px;">
                            Um e-mail foi enviado para o próximo responsável, que continuara o processo de cadastro.
                        </div>
                        <div class="mx-auto">
                            <a href="javascript:$('#modalNoteResume').modal('hide')"
                               class="btn btn-block btn-warning mt-5"
                               type="button">fechar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<!--modal-->
<div class="modal-custom modal fade" id="fistAccess" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_content ">
            <div class="modal_header">
                <h3 class="header-title"><b>Primeiro acesso</b></h3>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="row my-3">
                    <div class="col-12">
                        <div class="input-material">
                            <input id="newPassword" [(ngModel)]="newPassword" class="form-control" type="password"
                                   required/>
                            <label for="newPassword">Nova Senha</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <input id="confirmPassword" [(ngModel)]="confirmPassword" class="form-control"
                                   type="password" required/>
                            <label for="confirmPassword">Confirmar Senha</label>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <button (click)="fistAccess()" class="btn btn-primary btn-lg text-white w-100"> Confirmar</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

