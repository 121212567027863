<svg-icon class="bell-icon" src="assets/svg/bell.svg"></svg-icon>
<a href="javascript:void(0)">
    <span class="notification_number">{{modelNotifications.length}}</span>
    <a *ngIf="modelNotifications.length !== 0" href="javascript:void(0)" (click)="deleteNotification()" class="notification_number number_hover">X</a>
</a>
<div class="header__expanded">
    <ul>
        <li *ngIf="modelNotifications.length === 0"><p class="pt-3 pb-3">Nenhum item encontrado.</p></li>
        <li *ngFor="let n of modelNotifications" class="d-flex justify-content-between align-items-start">
            <p><strong>{{n.title}}</strong><br />{{n.message}}</p>
            <a href="javascript:void('')" class="btn btn-sm btn-danger" (click)="actionNotificationRemove(n.id);">
                <svg-icon [svgClass]="'svg-icon-white'" src="assets/svg/trash.svg"></svg-icon></a>
        </li>
    </ul>
</div>
